import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import lendingConfig from 'config/constants/lending'
import { getNullAddress } from 'utils/addressHelpers'
import fetchVaults from './fetchLendings'
import {
  fetchVaultUserEarnings,
  fetchVaultUserAllowances,
  fetchVaultUserTokenBalances,
  fetchVaultUserStakedBalances,
} from './fetchLendingUser'
import { Lending, LendingState, Vault } from '../types'

const nullAdd = getNullAddress()

const noAccountVaultConfig = lendingConfig.map((vault) => ({
  ...vault,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    baseEarnings: '0',
  },
}))

const initialState: LendingState = { data: noAccountVaultConfig, loadArchivedVaultsData: false, userDataLoaded: false }

// Async thunks
export const fetchLendingPublicDataAsync = createAsyncThunk<Lending[], string[]>(
  'lending/fetchLendingPublicDataAsync',
  async () =>
    // addresses?: string[]
    {
      const vaultsToFetch = lendingConfig

      const vaults = await fetchVaults(vaultsToFetch)

      return vaults
    },
)

interface VaultUserDataResponse {
  pid: number
  vaultAddress: string
  allowance: string
  tokenBalance: string
  stakedBalance: string
  baseEarnings: string
}

export const fetchLendingUserDataAsync = createAsyncThunk<
  VaultUserDataResponse[],
  { account: string; pids?: number[] }
>('lending/fetchLendingUserDataAsync', async ({ account, pids }) => {
  const vaultsToFetch = lendingConfig
  const userVaultAllowances = await fetchVaultUserAllowances(account, vaultsToFetch)
  const userVaultTokenBalances = await fetchVaultUserTokenBalances(account, vaultsToFetch)
  const userStakedBalances = await fetchVaultUserStakedBalances(account, vaultsToFetch)
  const userVaultEarnings = await fetchVaultUserEarnings(account, vaultsToFetch)

  return userVaultAllowances.map((vaultAllowance, index) => {
    return {
      pid: vaultsToFetch[index].pid,
      allowance: userVaultAllowances[index],
      tokenBalance: userVaultTokenBalances[index],
      stakedBalance: userStakedBalances[index],
      baseEarnings: userVaultEarnings[index],
    }
  })
})

export const vaultsSlice = createSlice({
  name: 'Lending',
  initialState,
  reducers: {
    setLoadArchivedVaultsData: (state, action) => {
      const loadArchivedVaultsData = action.payload
      state.loadArchivedVaultsData = loadArchivedVaultsData
    },
  },
  extraReducers: (builder) => {
    // Update vaults with live data
    builder.addCase(fetchLendingPublicDataAsync.fulfilled, (state, action) => {
      state.data = state.data.map((vault) => {
        const liveVaultData = action.payload.find((vaultData) => vaultData.pid === vault.pid)
        return { ...vault, ...liveVaultData }
      })
    })

    // Update vaults with user data
    builder.addCase(fetchLendingUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { pid } = userDataEl
        const index = state.data.findIndex((vault) => vault.pid === pid)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
  },
})

// Actions
export const { setLoadArchivedVaultsData } = vaultsSlice.actions

export default vaultsSlice.reducer
