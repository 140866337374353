import BigNumber from 'bignumber.js'
import { BLOCKS_PER_YEAR, FINS_PER_YEAR, JAWS_PER_YEAR } from 'config'
import lpAprs from 'config/constants/lpAprs.json'
import pools from 'config/constants/dividends'
import { Dividend, Lending, Pool, SerializedBigNumber, Vault } from 'state/types'
import { BIG_TEN } from './bigNumber'

const SECONDS_PER_YEAR = 365 * 24 * 60 * 60
const BLOCKS_IN_A_YEAR = SECONDS_PER_YEAR / 14

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
  canMint: boolean,
  bnbPrice: BigNumber,
  jawsPerProfitBNB: BigNumber,
  jawsPrice: BigNumber,
  boostRate: number,
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)

  // const cakeRewardsApr = yearlyTokenRewardAllocation.times(nativePriceUsd).div(poolLiquidityUsd).times(100)
  const actualCakeRewardsApr = apr.times(canMint ? 70 : 100).div(100)
  const actualJawsRewardsApr = apr
    .times(canMint ? 30 * boostRate : 0)
    .div(100)
    .div(bnbPrice)
    .times(jawsPerProfitBNB)
    .times(jawsPrice)

  const finalAPR = actualCakeRewardsApr.plus(
    actualJawsRewardsApr.isNaN() || !actualJawsRewardsApr.isFinite() ? 0 : actualJawsRewardsApr,
  )
  return finalAPR.isNaN() || !finalAPR.isFinite() ? null : finalAPR.toNumber()
}

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @returns
 */
export const getFarmApr = (
  poolWeight: BigNumber,
  cakePriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  farmAddress: string,
  isFins?: boolean,
): { cakeRewardsApr: number; lpRewardsApr: number } => {
  const yearlyCakeRewardAllocation = isFins ? FINS_PER_YEAR.times(poolWeight) : JAWS_PER_YEAR.times(poolWeight)
  const cakeRewardsApr = yearlyCakeRewardAllocation.times(cakePriceUsd).div(poolLiquidityUsd).times(100)
  let cakeRewardsAprAsNumber = null
  if (!cakeRewardsApr.isNaN() && cakeRewardsApr.isFinite()) {
    cakeRewardsAprAsNumber = cakeRewardsApr.toNumber()
  }
  const lpRewardsApr = lpAprs[farmAddress?.toLocaleLowerCase()] ?? 0
  return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr }
}

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @returns
 */
export const getVaultApr = (
  poolWeight: BigNumber,
  nativePriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  nativeTokenPerBlock: SerializedBigNumber,
  farmAddress: string,
  canMint: boolean,
  bnbPrice: BigNumber,
  jawsPerProfitBNB: BigNumber,
  jawsPrice: BigNumber,
  boostRate: number,
  singleMaximizer: boolean,
  lpMaximizer: boolean,
  farmTokenPrice: BigNumber,
  lpTokenPrice: BigNumber,
  vault: Vault,
  singlePool: Pool,
  dividends: Dividend[],
): { cakeRewardsApr: number; lpRewardsApr: number } => {
  if (vault.isFairLaunch && vault.apex) {
    const borrowingApr = getBorrowingApr(
      new BigNumber(vault.vaultDebtVal).toNumber(),
      new BigNumber(vault.totalToken).toNumber(),
    )
    const baseApr = getLendingApr(
      new BigNumber(vault.vaultDebtVal).toNumber(),
      new BigNumber(vault.totalToken).toNumber(),
      borrowingApr,
    )
    const dailyApr = baseApr * 100

    // const pool = dividends.find((item) => item.id === vault.maximizerId)
    // const { rewardRate, totalSupply } = pool
    // const dailyRewards = new BigNumber(rewardRate).times(86400).times(lpTokenPrice)
    // const totalPrice = new BigNumber(totalSupply).times(farmTokenPrice)
    // const actualDailyROI = dailyRewards.div(totalPrice).times(100)
    const actualDailyROI = new BigNumber(singlePool?.apr).div(365)
    const actualAPR = sumYieldForYear(dailyApr / 365, actualDailyROI.toNumber())
    const actualJawsRewardsApr = new BigNumber(actualAPR)
      .times(canMint ? 30 * boostRate : 0)
      .div(100)
      .div(bnbPrice)
      .times(jawsPerProfitBNB)
      .times(jawsPrice)
    const finalAPR = actualJawsRewardsApr.plus(dailyApr)
    return { cakeRewardsApr: finalAPR.toNumber(), lpRewardsApr: 0 }
  }

  if (vault.isFairLaunch) {
    const borrowingApr = getBorrowingApr(
      new BigNumber(vault.vaultDebtVal).toNumber(),
      new BigNumber(vault.totalToken).toNumber(),
    )
    const baseApr = getLendingApr(
      new BigNumber(vault.vaultDebtVal).toNumber(),
      new BigNumber(vault.totalToken).toNumber(),
      borrowingApr,
    )
    const dailyApr = baseApr * 100
    const actualJawsRewardsApr = new BigNumber(dailyApr)
      .times(canMint ? 30 * boostRate : 0)
      .div(100)
      .div(bnbPrice)
      .times(jawsPerProfitBNB)
      .times(jawsPrice)
    const finalAPR = actualJawsRewardsApr.plus(dailyApr)
    return { cakeRewardsApr: finalAPR.toNumber(), lpRewardsApr: 0 }
  }

  if (singleMaximizer && lpMaximizer) {
    const yearlyTokenRewardAllocation = new BigNumber(nativeTokenPerBlock)
      .div(new BigNumber('1000000000000000000'))
      .times(BLOCKS_PER_YEAR)
      .times(poolWeight)

    const cakeRewardsApr = yearlyTokenRewardAllocation.times(nativePriceUsd).div(poolLiquidityUsd).times(100)
    // const dailyAPY = aprToApy(cakeRewardsApr.toNumber(), 365)

    // let x = 0.5% (daily flip apr)
    // let y = 0.87% (daily fins apr)
    // sum of yield of the year = x*(1+y)^365 + x*(1+y)^364 + x*(1+y)^363 + ... + x
    // ref: https://en.wikipedia.org/wiki/Geometric_series
    // = x * (1-(1+y)^365) / (1-(1+y))
    // = x * ((1+y)^365 - 1) / (y)
    // const pool = dividends.find((item) => item.id === vault.maximizerId)

    // const { rewardRate, totalSupply } = pool
    // const dailyRewards = new BigNumber(rewardRate).times(86400).times(lpTokenPrice)
    // const totalPrice = new BigNumber(totalSupply).times(farmTokenPrice)
    // const actualDailyROI = dailyRewards.div(totalPrice).times(100)
    const actualDailyROI = new BigNumber(singlePool?.apr).div(365)

    const actualAPR = sumYieldForYear(cakeRewardsApr.div(365).toNumber(), actualDailyROI.toNumber())

    const actualCakeRewardsApr = new BigNumber(actualAPR).times(canMint ? 70 : 100).div(100)
    const actualJawsRewardsApr = new BigNumber(actualAPR)
      .times(canMint ? 30 * boostRate : 0)
      .div(100)
      .div(bnbPrice)
      .times(jawsPerProfitBNB)
      .times(jawsPrice)
    const finalAPR = actualCakeRewardsApr.plus(actualJawsRewardsApr)
    return { cakeRewardsApr: finalAPR.toNumber(), lpRewardsApr: 0 }
  }

  if (singleMaximizer) {
    const pool = dividends.find((item) => item.id === vault.pid)
    const { rewardRate, totalSupply } = pool
    const dailyRewards = new BigNumber(rewardRate).times(86400).times(lpTokenPrice)
    const totalPrice = new BigNumber(totalSupply).times(farmTokenPrice)
    let actualDailyROI = new BigNumber(singlePool?.apr).div(365)

    if (pool.stakingToken.symbol === 'JAWS') {
      actualDailyROI = dailyRewards.div(totalPrice).times(100).multipliedBy(boostRate)
    }

    // If this is the FINS dividend pool, we add JAWS rewards
    // if (pool.id === 1 || pool.id === 3) {
    //   actualDailyROI = dailyRewards.div(totalPrice).times(100)
    //   const actualCakeRewardsApr = actualDailyROI
    //     .times(365)
    //     .times(canMint ? 70 : 100)
    //     .div(100)
    //   const actualJawsRewardsApr = actualDailyROI
    //     .times(365)
    //     .times(canMint ? 30 * boostRate : 0)
    //     .div(100)
    //     .div(bnbPrice)
    //     .times(jawsPerProfitBNB)
    //     .times(jawsPrice)
    //   const actualAPR = actualCakeRewardsApr.plus(actualJawsRewardsApr)
    //   return { cakeRewardsApr: actualAPR.toNumber(), lpRewardsApr: 0 }
    // }

    const _cakeRewardsApr = actualDailyROI.times(365).toNumber()
    return { cakeRewardsApr: _cakeRewardsApr, lpRewardsApr: 0 }
  }

  const yearlyTokenRewardAllocation = new BigNumber(nativeTokenPerBlock)
    .div(new BigNumber('1000000000000000000'))
    .times(BLOCKS_PER_YEAR)
    .times(poolWeight)

  const cakeRewardsApr = yearlyTokenRewardAllocation.times(nativePriceUsd).div(poolLiquidityUsd).times(100)
  const actualCakeRewardsApr = cakeRewardsApr.times(canMint ? 70 : 100).div(100)
  const actualJawsRewardsApr = cakeRewardsApr
    .times(canMint ? 30 * boostRate : 0)
    .div(100)
    .div(bnbPrice)
    .times(jawsPerProfitBNB)
    .times(jawsPrice)

  const actualAPR = actualCakeRewardsApr.plus(actualJawsRewardsApr)
  let cakeRewardsAprAsNumber = null
  if (!actualAPR.isNaN() && actualAPR.isFinite()) {
    cakeRewardsAprAsNumber = actualAPR.toNumber()
  }
  const lpRewardsApr = lpAprs[farmAddress?.toLocaleLowerCase()] ?? 0
  return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr }
}

export const getApexVaultApr = (
  cakeApr: BigNumber,
  canMint: boolean,
  bnbPrice: BigNumber,
  jawsPerProfitBNB: BigNumber,
  jawsPrice: BigNumber,
  finsPrice: BigNumber,
  finsBnbPrice: BigNumber,
  boostRate: number,
  singlePool: Pool,
  dividends: Dividend[]
): { oneDayROI: number; sevenDayROI: number; thirtyDayROI: number; yearlyROI: number; apexVaultApr: number } => {
  // dividends
  const pool = dividends.find((item) => item.id === 1)

  const { rewardRate, totalSupply } = pool
  const dailyRewards = new BigNumber(rewardRate).times(86400).times(finsBnbPrice)
  const totalPrice = new BigNumber(totalSupply).times(finsPrice)
  const actualDailyROI = dailyRewards.div(totalPrice).times(100)
  const finsApr = actualDailyROI.times(365)

  const dailyStakingReward = cakeApr.div(36500).toNumber()
  const dailyFinsReward = 155 / 36500

  const oneDayROI = dailyStakingReward
  let sevenDayROI = 0
  let thirtyDayROI = 0
  let yearlyROI = dailyStakingReward

  for (let i = 365; i > 1; i--) {
    yearlyROI = yearlyROI + yearlyROI * dailyFinsReward + dailyStakingReward
    if (i === 365 - 6) {
      sevenDayROI = yearlyROI
    }
    if (i === 365 - 29) {
      thirtyDayROI = yearlyROI
    }
  }

  const actualCakeRewardsOneDayROI = new BigNumber(oneDayROI * 100).times(canMint ? 70 : 100).div(100)
  const actualJawsRewardsOneDayROI = new BigNumber(oneDayROI * 100)
    .times(canMint ? 30 * boostRate : 0)
    .div(100)
    .div(bnbPrice)
    .times(jawsPerProfitBNB)
    .times(jawsPrice)

  const actualCakeRewardsSevenDayROI = new BigNumber(sevenDayROI * 100).times(canMint ? 70 : 100).div(100)
  const actualJawsRewardsSevenDayROI = new BigNumber(sevenDayROI * 100)
    .times(canMint ? 30 * boostRate : 0)
    .div(100)
    .div(bnbPrice)
    .times(jawsPerProfitBNB)
    .times(jawsPrice)

  const actualCakeRewardsThirtyDayROI = new BigNumber(thirtyDayROI * 100).times(canMint ? 70 : 100).div(100)
  const actualJawsRewardsThirtyDayROI = new BigNumber(thirtyDayROI * 100)
    .times(canMint ? 30 * boostRate : 0)
    .div(100)
    .div(bnbPrice)
    .times(jawsPerProfitBNB)
    .times(jawsPrice)

  const apr = apyToApr(yearlyROI * 100, 365)
  const actualCakeRewardsApr = new BigNumber(apr).times(canMint ? 70 : 100).div(100)
  const actualJawsRewardsApr = new BigNumber(apr)
    .times(canMint ? 30 * boostRate : 0)
    .div(100)
    .div(bnbPrice)
    .times(jawsPerProfitBNB)
    .times(jawsPrice)
  const finalAPR = actualCakeRewardsApr.plus(actualJawsRewardsApr)

  return {
    oneDayROI: actualCakeRewardsOneDayROI.plus(actualJawsRewardsOneDayROI).toNumber(),
    sevenDayROI: actualCakeRewardsSevenDayROI.plus(actualJawsRewardsSevenDayROI).toNumber(),
    thirtyDayROI: actualCakeRewardsThirtyDayROI.plus(actualJawsRewardsThirtyDayROI).toNumber(),
    yearlyROI: aprToApy(finalAPR.toNumber()),
    apexVaultApr: finalAPR.toNumber(),
  }
}

export const getFairlaunchApr = (vault: Lending) => {
  const borrowingApr = getBorrowingApr(
    new BigNumber(vault.vaultDebtVal).toNumber(),
    new BigNumber(vault.totalToken).toNumber(),
  )
  const baseApr = getLendingApr(
    new BigNumber(vault.vaultDebtVal).toNumber(),
    new BigNumber(vault.totalToken).toNumber(),
    borrowingApr,
  )
  const dailyApr = baseApr * 100

  return { cakeRewardsApr: dailyApr, lpRewardsApr: 0 }
}

// Refer to: https://docs.alpacafinance.org/our-protocol-1/global-parameters#interest-rate-model
export const getBorrowingApr = (vaultDebtVal: number, totalToken: number) => {
  const utilizationRate = vaultDebtVal / totalToken // must return a ratio, e.g. 0.1723

  if (utilizationRate < 0.6) {
    return (1 / 3) * utilizationRate + 0
  }
  if (utilizationRate < 0.9) {
    return 0 * utilizationRate + 0.2
  }
  return 13 * utilizationRate - 11.5
}

export const getLendingApr = (vaultDebtVal: number, totalToken: number, borrowingApr: number) => {
  const utilizationRate = vaultDebtVal / totalToken // must return a ratio, e.g. 0.1723
  // borrowingInterest is defined as ratio as well
  // Lending performance fee of 19%, hence 0.19
  return borrowingApr * utilizationRate * (1 - 0.19)
}

const sumYieldForYear = (x: number, y: number) => {
  const percentX = x / 100
  const percentY = y / 100

  // let x = 0.5% (daily flip apr)
  // let y = 0.87% (daily fins apr)
  // sum of yield of the year = x*(1+y)^365 + x*(1+y)^364 + x*(1+y)^363 + ... + x
  // ref: https://en.wikipedia.org/wiki/Geometric_series
  // = x * (1-(1+y)^365) / (1-(1+y))
  // = x * ((1+y)^365 - 1) / (y)
  let result = 0
  for (let i = 365; i > 1; i--) {
    result += (percentX * (1 + percentY)) ** i
  }
  result += percentX
  // return percentX * ((1+percentY)**365 - 1) / (percentY)
  return result * 100 * 365
}

/**
 * Formula source: http://www.linked8.com/blog/158-apy-to-apr-and-apr-to-apy-calculation-methodologies
 *
 * @param interest {Number} APY as percentage (ie. 6)
 * @param frequency {Number} Compounding frequency (times a year)
 * @returns {Number} APR as percentage (ie. 5.82 for APY of 6%)
 */
export const apyToApr = (interest: number, frequency = 365) =>
  ((1 + interest / 100) ** (1 / frequency) - 1) * frequency * 100

/**
 * Formula source: http://www.linked8.com/blog/158-apy-to-apr-and-apr-to-apy-calculation-methodologies
 *
 * @param interest {Number} APR as percentage (ie. 5.82)
 * @param frequency {Number} Compounding frequency (times a year)
 * @returns {Number} APY as percentage (ie. 6 for APR of 5.82%)
 */
export const aprToApy = (interest: number, frequency = BLOCKS_IN_A_YEAR) =>
  ((1 + interest / 100 / frequency) ** frequency - 1) * 100

export const combinedApy = (
  apy: number,
  bnbPrice: number,
  tokenPerProfitBNB: number,
  ourTokenPrice: number,
  taxReduction: number,
  multiplier?: number,
  minterOn?: boolean,
) => {
  const initialAPY = minterOn ? apy * 0.7 : apy
  const rewardAPR = apyToApr(initialAPY)
  const rewardAPY = aprToApy(rewardAPR * (1 - taxReduction / 100))

  const ourTokenPerProfitBNBMultiply = multiplier ? tokenPerProfitBNB * multiplier : tokenPerProfitBNB
  const ourTokenAPY = (apy * 0.3 * (ourTokenPerProfitBNBMultiply * ourTokenPrice)) / bnbPrice

  const netAPY = rewardAPY + ourTokenAPY

  if (minterOn) {
    return netAPY
  }
  return initialAPY
}

export default null
