import { Lending } from 'state/types'
import fetchPublicLendingData from './fetchPublicLendingData'

const fetchLending = async (vault: Lending): Promise<Lending> => {
  const vaultPublicData = await fetchPublicLendingData(vault)

  return { ...vault, ...vaultPublicData }
}

export default fetchLending
