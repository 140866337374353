import { getOceanMasterChefAddress } from 'utils/addressHelpers'
import tokens from './tokens'
import { FarmConfig } from './types'
import { CAKE_MASTER_CHEF, FINS_MASTER_CHEF } from './vaults'

export const originalFarms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  // {
  //   pid: 0,
  //   lpSymbol: 'CAKE',
  //   lpAddresses: {
  //     97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
  //     56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   },
  //   token: tokens.cake,
  //   quoteToken: tokens.wbnb,
  // },
  {
    pid: 251,
    lpSymbol: 'CAKE-BNB LP',
    lpAddresses: {
      97: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 252,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
]

const farms: FarmConfig[] = [
  /**
   * These 2 farms (PID 1, 2) should always be at the top of the file.
   */
  {
    pid: 1,
    lpSymbol: 'FINS-BNB',
    lpAddresses: {
      97: '',
      56: '0x14B5a6d26577970953F9E6608d6604e4676Ac5b7',
    },
    token: tokens.fins,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 2,
    lpSymbol: 'JAWS-BNB',
    lpAddresses: {
      97: '',
      56: '0x1BbA5C6Cc38EF04066042237b4298dC23405304D',
    },
    token: tokens.jaws,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 5,
    lpSymbol: 'CAKE-BNB',
    lpAddresses: {
      97: '',
      56: '0xCe39f9c2FffE30E093b54DcEa5bf45C727290985',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 40,
    lpSymbol: 'ATLAS-BUSD',
    lpAddresses: {
      97: '',
      56: '0x8eC2dCc0B88ef879C885B0b31e87aBa14543a8cd',
    },
    token: tokens.atlas,
    quoteToken: tokens.busd,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 4,
    lpSymbol: 'BUSD-USDT',
    lpAddresses: {
      97: '',
      56: '0x06347b91DaBc757cCD5A5AA2aF802cEc78e4D239',
    },
    token: tokens.busd,
    quoteToken: tokens.usdt,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 3,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0xD636EA3609615ad8Aa667509c50946098454EaD2',
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 15,
    lpSymbol: 'BNB-USDT',
    lpAddresses: {
      97: '',
      56: '0xBbbB885789Bcc958DA3de78748E0f40510d6a99b',
    },
    token: tokens.wbnb,
    quoteToken: tokens.usdt,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 10,
    lpSymbol: 'USDT-USDC',
    lpAddresses: {
      97: '',
      56: '0x81E6Cd6AdeDA14D94993aa9D97193B7266001cD3',
    },
    token: tokens.usdc,
    quoteToken: tokens.usdt,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 9,
    lpSymbol: 'DAI-USDT',
    lpAddresses: {
      97: '',
      56: '0xbD78f8Cf00e03C00afAf684B72D08CF8F997d4Bb',
    },
    token: tokens.dai,
    quoteToken: tokens.usdt,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 25,
    lpSymbol: 'BTC-ETH',
    lpAddresses: {
      97: '',
      56: '0x3942940EfeEF8C6435b722082beB3Eb2837EBD69',
    },
    token: tokens.btcb,
    quoteToken: tokens.eth,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 8,
    lpSymbol: 'BTCB-BNB',
    lpAddresses: {
      97: '',
      56: '0x51d267099Ccc9C67A5B4A69e3bb71693dffF29Ed',
    },
    token: tokens.btcb,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 7,
    lpSymbol: 'ETH-BNB',
    lpAddresses: {
      97: '',
      56: '0x3ce41b541553AafB795Dfc27C4c9271772515aFF',
    },
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 6,
    lpSymbol: 'LINK-BNB',
    lpAddresses: {
      97: '',
      56: '0xA7aBA4AE7881f482a0D2D132d6e4DC985f3cFa24',
    },
    token: tokens.link,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 12,
    lpSymbol: 'DOT-BNB',
    lpAddresses: {
      97: '',
      56: '0x7E7e1421a66fcf66e145d1ef204A517eB217B1a0',
    },
    token: tokens.dot,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 16,
    lpSymbol: 'BNB-USDC',
    lpAddresses: {
      97: '',
      56: '0x84d455d0b1FFb0b488CBD1f4b098f076e5628065',
    },
    token: tokens.usdc,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 34,
    lpSymbol: 'SHIB-BNB',
    lpAddresses: {
      97: '',
      56: '0x16Fe186ef89489e4B8E6953D112b657F2854D566',
    },
    token: tokens.shib,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 19,
    lpSymbol: 'FTM-BNB',
    lpAddresses: {
      97: '',
      56: '0xDf413999fc2f20fA353e1377Ea0B621Bc54e43A3',
    },
    token: tokens.ftm,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 42,
    lpSymbol: 'SCY-BNB',
    lpAddresses: {
      97: '',
      56: '0x3468a7e17544b3a240fa4f9ed398ae6e9a4020f8',
    },
    token: tokens.scy,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 41,
    lpSymbol: 'BID-BNB',
    lpAddresses: {
      97: '',
      56: '0x79fccd1b6383eb0cf45b0cf225a3af2e76d6942b',
    },
    token: tokens.bid,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 39,
    lpSymbol: 'PRED-BNB',
    lpAddresses: {
      97: '',
      56: '0xd0e0eda61ea5cdfdfe4ede5a4d72fc5bb147e2ab',
    },
    token: tokens.pred,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 33,
    lpSymbol: 'SOL-BNB',
    lpAddresses: {
      97: '',
      56: '0x9d5B48AD38748c6DBD77399eccE3FD8B6f980456',
    },
    token: tokens.sol,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 38,
    lpSymbol: 'LIQ-BNB',
    lpAddresses: {
      97: '',
      56: '0xA2cAff204D26fd3B7D87Ba5D15E63748Ba1e485b',
    },
    token: tokens.liq,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 14,
    lpSymbol: 'ADA-BNB',
    lpAddresses: {
      97: '',
      56: '0xAc246B90AA9F5D372D9722E32119E364CA5Ad77A',
    },
    token: tokens.ada,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 13,
    lpSymbol: 'XRP-BNB',
    lpAddresses: {
      97: '',
      56: '0xcb257133C82Fe7488c49723E1Ef99b35E578A08B',
    },
    token: tokens.xrp,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 20,
    lpSymbol: 'AVAX-BNB',
    lpAddresses: {
      97: '',
      56: '0xc244d8ff0930bc104f1fdb5ee1b279f00a096005',
    },
    token: tokens.avax,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 31,
    lpSymbol: 'AAVE-BNB',
    lpAddresses: {
      97: '',
      56: '0x01Abb7c786d74358161d7e8e70991C18e69f1dDc',
    },
    token: tokens.aave,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 30,
    lpSymbol: 'EOS-BNB',
    lpAddresses: {
      97: '',
      56: '0xaeA36B93e5D4093664ED86A736DbC184678016cF',
    },
    token: tokens.eos,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 29,
    lpSymbol: 'FIL-BNB',
    lpAddresses: {
      97: '',
      56: '0xa87AED2375cdCbCA69f3D1D71C69ed80D344FC5f',
    },
    token: tokens.fil,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 17,
    lpSymbol: 'ALPACA-BNB',
    lpAddresses: {
      97: '',
      56: '0x37A614AEBa812332bB18D7E5D040470F61a5CA75',
    },
    token: tokens.alpaca,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 18,
    lpSymbol: 'MBOX-BNB',
    lpAddresses: {
      97: '',
      56: '0x69Faa9E9C0A686EbeD4eCa20759dA78189A3F9ea',
    },
    token: tokens.mbox,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 21,
    lpSymbol: 'AXS-BNB',
    lpAddresses: {
      97: '',
      56: '0xad8b818A44a29A1c63Ef7C75cDD8363c626E0eFB',
    },
    token: tokens.axs,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 26,
    lpSymbol: 'BSW-BNB',
    lpAddresses: {
      97: '',
      56: '0xE0e957b53b61e70CACA097054d882FAC2562d348',
    },
    token: tokens.bsw,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 22,
    lpSymbol: 'BANANA-BNB',
    lpAddresses: {
      97: '',
      56: '0x3b81994d277017631b722b0f8817ccb659225462',
    },
    token: tokens.banana,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 11,
    lpSymbol: 'PIRATE-BNB',
    lpAddresses: {
      97: '',
      56: '0x4A69AEcc8D54f4C547388f5B5E60AC525B868275',
    },
    token: tokens.pirate,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 28,
    lpSymbol: 'KALA-BUSD',
    lpAddresses: {
      97: '',
      56: '0xCa58CCB5413eb47142F0D12aD9D2BBFCDF3a4148',
    },
    token: tokens.kala,
    quoteToken: tokens.busd,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 23,
    lpSymbol: 'NFTART-BNB',
    lpAddresses: {
      97: '',
      56: '0xD5278CA7b099aF5a8690115DC25EDeF5d575132B',
    },
    token: tokens.nftart,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 24,
    lpSymbol: 'BYG-BNB',
    lpAddresses: {
      97: '',
      56: '0x78D1EdB91A158675369B2D2feBcC9F8266844346',
    },
    token: tokens.byg,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 27,
    lpSymbol: 'WNOW-BNB',
    lpAddresses: {
      97: '',
      56: '0xA1C836fb9a8F193A5De23688cecd08934a3f0d42',
    },
    token: tokens.wnow,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 32,
    lpSymbol: 'MEOW-BNB',
    lpAddresses: {
      97: '',
      56: '0x96190CBFADFB0D9b24faB3fa149fe0bbeAb55B18',
    },
    token: tokens.meow,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 36,
    lpSymbol: 'GUARD-BUSD',
    lpAddresses: {
      97: '',
      56: '0x96f1f0f2268c67632f9ddcd8acb1f60011e0ebfd',
    },
    token: tokens.guard,
    quoteToken: tokens.busd,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 35,
    lpSymbol: 'ZMBE-BNB',
    lpAddresses: {
      97: '',
      56: '0x9478DFb372cF5bDB2c87B8AE712698E8C8460c3e',
    },
    token: tokens.zmbe,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 37,
    lpSymbol: 'ACSI-BNB',
    lpAddresses: {
      97: '',
      56: '0x9833b3434CC8ea1BD4785C9C9aD245F343941627',
    },
    token: tokens.acsi,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 44,
    lpSymbol: 'iBFR-BNB',
    lpAddresses: {
      97: '',
      56: '0x47b172472a7bec83352a67d04f5358723392bf0c',
    },
    token: tokens.ibfr,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 45,
    lpSymbol: 'YUKLA-BNB',
    lpAddresses: {
      97: '',
      56: '0xc3D2B8cE3716f63E0Dd8E0Ad08c10D1E7b8E3202',
    },
    token: tokens.yukla,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },
  {
    pid: 46,
    lpSymbol: 'DEP-BNB',
    lpAddresses: {
      97: '',
      56: '0x1166e238c328830a3b4a32a38bb93ca696ebb898',
    },
    token: tokens.dep,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    pendingOutput: 'pendingFins',
  },

  // JAWS farm
  {
    pid: 2,
    lpSymbol: 'JAWS-BNB ',
    lpAddresses: {
      97: '',
      56: '0x1BbA5C6Cc38EF04066042237b4298dC23405304D',
    },
    token: tokens.jaws,
    quoteToken: tokens.wbnb,
    earningToken: tokens.jaws,
    masterChef: getOceanMasterChefAddress(),
    masterChefOutput: 'jawsPerBlock',
    pendingOutput: 'pendingJaws',
    farmType: 0,
  },
  {
    pid: 1,
    lpSymbol: 'JAWS-BNB',
    lpAddresses: {
      97: '',
      56: '0x9348B45c542db53FE750F01F014BF6ae04b1395a',
    },
    token: tokens.jaws,
    quoteToken: tokens.wbnb,
    earningToken: tokens.jaws,
    masterChef: getOceanMasterChefAddress(),
    masterChefOutput: 'jawsPerBlock',
    pendingOutput: 'pendingJaws',
    farmType: 0,
  },
]

export const priceHelpers: FarmConfig[] = [
  {
    pid: 252,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    masterChef: CAKE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    pendingOutput: 'pendingCake',
  },
  {
    pid: 264,
    lpSymbol: 'USDT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
    },
    token: tokens.usdt,
    quoteToken: tokens.wbnb,
    masterChef: CAKE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    pendingOutput: 'pendingCake',
  },
]

export default farms
