import config from './lending.json'
import tokens from './tokens'

// Denotes the lending pools
const lending = [
  {
    pid: 1,
    symbol: 'BNB',
    lpAddresses: {
      97: '',
      56: '0x9d5B48AD38748c6DBD77399eccE3FD8B6f980456',
    },
    vault: config.Vaults.find((item) => item.symbol === 'ibWBNB'),
    ibToken: config.Vaults.find((item) => item.symbol === 'ibWBNB')?.address,
    token: tokens.wbnb,
    quoteToken: tokens.wbnb,
    earningToken: tokens.fins,
    masterChef: config.FairLaunch.address,
    masterChefOutput: 'alpacaPerBlock',
    pendingOutput: 'pendingAlpaca',
  },
  {
    pid: 3,
    symbol: 'BUSD',
    lpAddresses: {
      97: '',
      56: '0x9d5B48AD38748c6DBD77399eccE3FD8B6f980456',
    },
    vault: config.Vaults.find((item) => item.symbol === 'ibBUSD'),
    ibToken: config.Vaults.find((item) => item.symbol === 'ibBUSD')?.address,
    token: tokens.busd,
    quoteToken: tokens.busd,
    earningToken: tokens.fins,
    masterChef: config.FairLaunch.address,
    masterChefOutput: 'alpacaPerBlock',
    pendingOutput: 'pendingAlpaca',
  },
]

export default lending
