import React from 'react'
import styled from 'styled-components'
import { Text, useMatchBreakpoints } from '@autoshark-finance/uikit'
import { Pool } from 'state/types'
import { useBlockNumber } from 'state/application/hooks'
import { useTranslation } from 'contexts/Localization'
import BaseCell, { CellContent } from './BaseCell'
import Apr from '../Apr'

interface AprCellProps {
  pool: Pool
  performanceFee: number
}

const StyledCell = styled(BaseCell)`
  flex: 1 0 50px;
  ${({ theme }) => theme.mediaQueries.md} {
    flex: 0 0 120px;
  }
`

const AprCell: React.FC<AprCellProps> = ({ pool, performanceFee }) => {
  const { t } = useTranslation()
  const { isXs, isSm } = useMatchBreakpoints()
  const { isAutoVault } = pool
  const currentBlock = useBlockNumber()
  const blocks =
    currentBlock > pool.startBlock
      ? currentBlock > pool.endBlock
        ? pool.endBlock - pool.startBlock
        : currentBlock - pool.startBlock
      : 0
  const tokensPerBlock =
    pool.stakingToken.symbol === 'cMERL' ? 0.000496031746031746 : Number.parseFloat(pool.tokenPerBlock)
  const paid = blocks * tokensPerBlock * pool.earningTokenPrice

  return (
    <StyledCell role="cell">
      {pool.hideAPR ? (
        <CellContent>
          <Text fontSize="12px" color="textSubtle" textAlign="left">
            Total Paid
          </Text>
          <span style={{ color: '#fff', paddingTop: 2 }}>${Number.isNaN(paid) ? 0 : paid?.toFixed(2)}</span>
        </CellContent>
      ) : (
        <CellContent>
          <Text fontSize="12px" color="textSubtle" textAlign="left">
            {isAutoVault ? t('APY') : t('APR')}
          </Text>
          <Apr pool={pool} performanceFee={isAutoVault ? performanceFee : 0} showIcon={!isXs && !isSm} />
        </CellContent>
      )}
    </StyledCell>
  )
}

export default AprCell
