import { getOceanMasterChefAddress } from 'utils/addressHelpers'
import tokens from './tokens'
import { VaultConfig } from './types'

// active farms (v3 onwards)
export const PRICE_CALCULATOR_BSC = '0x41B471F347a7C2C8e6cb7F4F59C570C6D9c69a3C'
export const DASHBOARD_BSC = '0xa5251abdb5218699F09360DF17967C0e2ffA6655'
export const WEX_MASTER_CHEF = '0x22fB2663C7ca71Adc2cc99481C77Aaf21E152e2D'
export const CAKE_MASTER_CHEF = '0x73feaa1eE314F8c655E354234017bE2193C9E24E'
export const FINS_MASTER_CHEF = '0x8857Af8205F224870dea119e2c75AF386EFB192a'
export const BABY_MASTER_CHEF = '0xdfAa0e08e357dB0153927C7EaBB492d1F60aC730'
export const ALPACA_FAIR_LAUNCH = '0xA625AB01B08ce023B2a342Dbb12a16f2C8489A8F'
export const JET_MASTER_CHEF = '0x63d6EC1cDef04464287e2af710FFef9780B6f9F5'
export const PANTHER_MASTER_CHEF = '0x058451C62B96c594aD984370eDA8B6FD7197bbd4'
export const BISWAP_MASTER_CHEF = '0xDbc1A13490deeF9c3C12b44FE77b503c1B061739'
export const APE_MASTER_CHEF = '0x5c8D727b265DBAfaba67E050f2f739cAeEB4A6F9'
export const SQUID_MASTER_CHEF = '0x86A47ddD4c6522251d6a5A5800f3F24c03332CB4'
export const NOVA_MASTER_CHEF = '0x8A4f4c7F4804D30c718a76B3fde75f2e0cFd8712'
export const HONEY_MASTER_CHEF = '0x671e56C68047029F236f342b18632425C75885a3'
// Panther vaults
export const PANTHER_BNB_FLIP_VAULT_V3 = '0xD50f3900B047f38eB6eBc946142A3C652BCc4240'
export const PANTHER_BUSD_FLIP_VAULT_V3 = '0xc1bf412E918e7ed4Df2f3438Fafd3fCadF9F3C15'
export const PANTHER_VAULT_V3 = '0x4A88DC9955aB00aD3Ac0dD96317072aB32Ab88B4'
export const BNB_BUSD_FLIP_VAULT_V3 = '0x8f463Ffcf6A4fdCca7A201D2c426a81224d1D00e'
export const BTCB_BNB_FLIP_VAULT_V3 = '0x63c4a54C84b214bbfCE9A550d18677745E05082D'
export const ETH_BNB_FLIP_VAULT_V3 = '0x357C1658517fE929afD38fbB0a65389bEa859EE1'
export const USDT_BUSD_FLIP_VAULT_V3 = '0xC4380b63c184A381011B2Da6aCC5c566C3EfecD7'
// Panther boosted vaults
export const JAWS_BUSD_FLIP_VAULT_V3 = '0xe916b00996B4ceCB490F47A6e6F57cF020A12a8e'
export const JAWS_BNB_FLIP_VAULT_V3 = '0xF0155Ced75d20B9eba9f08b1157D00b5682518d5'
export const PIRATE_BNB_POOL = '0x1DfddBd56ce15fD48295d19E5A1e1C9132ACe271'
// PCS vaults
export const CAKE_VAULT = '0xff4D5C1794946105eBfB5FcA3CBEbAB2C83E05cd'
export const CAKE_BNB_VAULT = '0x1a08FB9EAAdb29211FF58D8A7614E416525A01aC'
export const COMPENSATION_CAKE_VAULT = '0x5CCc16F3B60F96366CDA1D7049138abC91daD17F'
// Baby vaults
export const BABY_VAULT = '0x82C8CAA74748A85b621D90562BB0d9a7d14f6210'
export const BABY_USDT_VAULT = '0x71baf8DC7DD4a975FD6beFbdb810B9a72F848658'
export const BABY_BNB_VAULT = '0x3689E56c15f54D286Bc6Cd246e023769408AA7DD'
export const PIRATE_USDT_VAULT = '0x92CAbfE0741dC9d0A0Cb471668b5939D3Dd1FD2d'
export const JAWS_USDT_VAULT = '0x6F317d9E9FF9FE87a7D7f99af6c6EA6874CaeF4F'
export const GUARD_USDT_VAULT = '0x35968fd7761Ae868699126008b30137FF110bF04'
export const WOLFIES_USDT_VAULT = '0x6F2359B946CAf2372B692A0CB2dEE26FF98AFEC7'
// ShibaNova vaults
export const SHIBA_NOVA_VAULT = '0x6cAB4C89eFBb1e7938aBBf3Dd0363bCEd9A1505A'
export const SHIBA_NOVA_BNB_VAULT = '0xa6163f18FffB55027eAEBBDa46004Cd9210B4781'
// Honey vaults
export const MOON_BNB_VAULT = '0x3ae57251b9A6Ef3eCd07BDaEb7c657F615E17C69'
export const BEAR_BNB_VAULT = '0x74E0C7bE714Eb1abFB29f9BA4D0E82e27b95a79c'
export const BEAR_BUSD_VAULT = '0x5d790A9C694359455eD090f7d5Ae18415F3B8e61'
export const HONEY_BNB_VAULT = '0x0e2f5f1fC2c9Ea2D64aA95004D4b062440e78D46'
export const HONEY_BUSD_VAULT = '0xd90218F00053359E49057886a0459135456A5bF3'
// JET vaults
export const WINGS_VAULT = '0x5A4d3B100aC1fAd78881F480Bc3D988d06FF0B35'
export const WINGS_BNB_JET_VAULT = '0x5CCF410e58f09156fcB791e60198d892b3904484'
export const WINGS_BUSD_JET_VAULT = '0xAc22E9353313fe24e6D1331eCdee307D1Acba1A3'
export const FTS_BNB_JET_VAULT = '0xda3639094Bc109a182400FB98422e0569AbA6F64'
export const BNB_BUSD_JET_VAULT = '0x79022F86ADdaCfdaEb2819f62Da91EEe1F7F2e73'
// Ape vaults
export const BANANA_VAULT = '0xFF2692799fdc9Da85dE737884ab9C97e31B5F68F'
export const BANANA_BNB_APE_VAULT = '0x1a7F0407dc4Ab365EdFe69Eb75F387B0bB1c9ef7'
export const BANANA_BUSD_APE_VAULT = '0x79361947f8C73A191Baf47F48D5db7ed4B9c8Ef4'
export const WIZARD_WBNB_APE_VAULT = '0x468fe880F5E3f433E13d525B73ed04f4531c8542'
export const SISTA_WBNB_APE_VAULT = '0x7Eb59278B57B3FCb5BF2F8b32132bBd8E3a2Fbca'
export const DEP_WBNB_APE_VAULT = '0xB59233aB8fc3Cc8a7eB4586acdb55C3674Ba1829'
// Wault vaults
export const WEX_VAULT_V3 = '0x776e75F90170DE5D332A065C00C1636ffF3C8aE7'
export const PIRATE_BNB_WAULT_VAULT = '0x4Cb160E55aEab1fE937F09511ebd9f03366E35c4'
export const JAWS_BNB_WAULT_VAULT = '0xb3d84a9365B0C2cf6B0b17e16ecA6B1B6ca8f9d6'
export const WUSD_BUSD_VAULT = '0x3849cec84De609cb29ed2Cd13C04e5B0fB09B75A'
export const WIZARD_WUSD_WAULT_VAULT = '0x4b27fab067760a43EFc307DCCee5B81A8ce9b575'
// Poly vaults
export const POLY_PIRATE_POOL = '0x6a029e4A4F62E950f9783c65b8873BD9AeDAD0D2'
export const POLY_PIRATE_PIRATE_POOL = '0xb3C27619ae1D2F7748dA84d040c99D9F93143aa5'
// AutoShark vaults
export const FINS_BNB_MAXIMIZER_VAULT = '0xb38aF3202724a382f8925EF53E5397e4Fb330970'
export const JAWS_BNB_MAXIMIZER_VAULT = '0x0f291d9f6863c92F0583767aB19e8AF85ED5b87E'
export const BNB_BUSD_MAXIMIZER_VAULT = '0x2c3Af07E4E3e31C3F068df891956e64452A354b7'
export const BUSD_USDT_MAXIMIZER_VAULT = '0x91f78C457622D4fEbB82d8A3da76eFBcbcb70270'
export const BNB_USDT_MAXIMIZER_VAULT = '0x1D1eD8A9b78b664CfF50e1b8B26DC506DFb59ee0'
export const USDT_USDC_MAXIMIZER_VAULT = '0xCF5509C3C6D8A285f4B67eb7204a91E86AF5f6e5'
export const DAI_USDT_MAXIMIZER_VAULT = '0x18be0B2b747d8A7D81e9f074AB513B42a5e55417'
export const BTC_ETH_MAXIMIZER_VAULT = '0xD98142Af0EF7b12eeC63C18bBc89a456Ee26D1Cc'
// export const BTC_BNB_MAXIMIZER_VAULT = '0x3CfE95DF34f66D4EA52aC6189DEaEE5abD1D6a66'
export const CAKE_BNB_MAXIMIZER_VAULT = '0xf67e44711a52B4844DAe3e28Fe8D4fb817f9f097'
export const SQUID_BNB_MAXIMIZER_VAULT = '0xC73457a039f6E9C2DB30150aD977dcEE05bBA43c'
export const BNB_BUSD_SQUID_MAXIMIZER_VAULT = '0xbcf5eea9297bE23e7048d4530242D856a28001ea'
export const BUSD_USDT_SQUID_MAXIMIZER_VAULT = '0xAC38d141c4ad26559c874c65FD943318B02a2E9c'
export const USDT_USDC_SQUID_MAXIMIZER_VAULT = '0xA3711580B9732A3134E4CcCE56cf0d2cEEfBBC33'
export const CAKE_BNB_SQUID_MAXIMIZER_VAULT = '0xB193D27613C079c957b687bf9cAbacD52227a238'
export const BTCB_BNB_SQUID_MAXIMIZER_VAULT = '0x4a5B0fDa92B98B3A7255E6beFd9D374BE8C0e213'
export const ETH_BNB_SQUID_MAXIMIZER_VAULT = '0x1BF4a8D528c0E4e58947e16A069a31DbeAb27B1d'
export const USDT_BNB_SQUID_MAXIMIZER_VAULT = '0xEaf8d3114D81E8bb99eAD407CB0c5F8869D66754'
export const USDC_BNB_SQUID_MAXIMIZER_VAULT = '0x4fFEDa1279cAc6A2D4135a3f739ecC6c5bB0198a'
export const BTCB_ETH_SQUID_MAXIMIZER_VAULT = '0xA5C50cf719429238b7E8992C134F1b9AA53bA290'
export const LINK_BNB_SQUID_MAXIMIZER_VAULT = '0x230fF897D1402c9B5b9dc106e396C25fBAa54d1B'
export const DOT_BNB_SQUID_MAXIMIZER_VAULT = '0xC4db071EDFc792878e3387d33526f297350E4E5e'
export const SOL_BNB_SQUID_MAXIMIZER_VAULT = '0x46d49a4b07b8708f9D8DD4e5508ACC6135fAcc8E'
export const ADA_BNB_SQUID_MAXIMIZER_VAULT = '0xF2EA7854b952F25d752E743FFAD9D67D089F2962'

// export const ETH_BNB_MAXIMIZER_VAULT = '0xC5Ff34fbdA875Ccb705358C4F0fE2a52B11d804C'
// export const LINK_BNB_MAXIMIZER_VAULT = '0xAf942Be2D3a3353296Be0f4db313c7f6D2f6F506'
// export const DOT_BNB_MAXIMIZER_VAULT = '0x9E4D927c3025474197080933FfF6F685137c0c70'
// export const BNB_USDC_MAXIMIZER_VAULT = '0xE19b47047E5354148BdF866b7373BcE8B50D33E3'
// export const ADA_BNB_MAXIMIZER_VAULT = '0x14576fe89f357B91D2B497389A512f86D0bf6b2A'
export const PIRATE_BNB_MAXIMIZER_VAULT = '0x4D9F567e040368f689Ed3ACBC90D0d99147c89AA'

export const JAWS_BNB_JAWS_MAXIMIZER_VAULT_ERROR = '0x798C916B2da66253Cc9e4eaaB8380672d9c63ebC'
export const JAWS_BNB_JAWS_MAXIMIZER_VAULT = '0xADD989bb66E7dA357e99816d8bDBd1bB7de67415'

// Random vaults
export const JAWS_MAXIMIZER_VAULT = '0x51eF0D7D28aa936d6d390AD13eA53AeDe1234fa5'
export const FINS_MAXIMIZER_VAULT = '0xe08BdC73eDe1B9cFD095A2194078ba43c4Cf286B'
export const SQUID_MAXIMIZER_VAULT = '0x861adCCFabD9BDF2E1f5418297a444A33c93B9eb'
export const CAKE_TO_FINS_MAXIMIZER_VAULT = '0x0a603eEB2D3aFb3f095d079376241d61C36BcEf7'
export const BANANA_TO_FINS_MAXIMIZER_VAULT = '0x0Bd0325Db3F310Ee2fD237F8c9266A4580596EB8'
export const BSW_TO_FINS_MAXIMIZER_VAULT = '0xD97c769b93982F151be8428fbC166a3CB6f92ea5'
export const BABY_TO_FINS_MAXIMIZER_VAULT = '0x6aCb31a248c572e7d85d985c5F7A8b70e4246c64'
export const PIRATE_MAXIMIZER_VAULT = '0xCfAb543181cbfa87fF70e460f2b6e2D328cfAdF5'
export const BISWAP_VAULT_V3 = '0xA607dAa0626B5974660FD25239759D805f5Fba2e'
export const PIRATE_POOL = '0x9216ea2ea67E5E3Dae8d942929B420178e96cD89'
export const JAWS_FOR_JAWS_VAULT_V3 = '0xB022315eEA0197B0A910B91b7ED2D5015e12fe9d'

// SAVs
export const SINGLE_BNB_VAULT = '0xb37903f81933e506771bC3fc4D47F8BB1c6f8fe4'
export const SINGLE_BUSD_VAULT = '0x7711838fCE3C5EDD26BAe2D49cc503bDF04C3da7'
export const SINGLE_BTCB_VAULT = '0x2D95fdEFe422626f45Cd19a166f5AB63ecd1C9C7'
export const SINGLE_ETH_VAULT = '0x46Fc8f2577d04452719b26dC519A1Bf6A450650b'

export const SWAP_MAP: { [key: string]: string } = {
  pancake: 'PancakeSwap',
  biswap: 'Biswap',
  wault: 'WaultFinance',
  panther: 'PantherSwap',
  ape: 'ApeSwap',
  autoshark: 'AutoShark',
  'wault-poly': 'WaultFinance',
  polycrystal: 'PolyCrystal',
  baby: 'BabySwap',
  jet: 'JetSwap',
  nova: 'ShibaNova',
  honey: 'HoneyFarm',
}

export const SWAP_LIQUIDITY_URLS: { [key: string]: string } = {
  pancake: 'https://pancakeswap.finance/add/',
  biswap: 'https://exchange.biswap.org/#/add/',
  wault: 'https://swap.wault.finance/bsc/index.html#/add/',
  panther: 'https://dex.pantherswap.com/#/add/',
  ape: 'https://app.apeswap.finance/add/',
  'wault-poly': 'https://swap.wault.finance/polygon/index.html#/add/',
  polycrystal: 'https://dex.polycrystal.finance/#/add/',
  baby: 'https://exchange.babyswap.finance/#/pool/',
  jet: 'https://exchange.jetswap.finance/#/pool/',
  nova: 'https://swap.shibanova.io/#/pool',
  honey: 'https://pancakeswap.finance/add/',
  autoshark: 'https://autoshark.finance/add/',
}

export const SWAP_URLS: { [key: string]: string } = {
  pancake: 'https://pancakeswap.finance/swap/',
  biswap: 'https://exchange.biswap.org/#/swap/',
  wault: 'https://swap.wault.finance/bsc/index.html#/swap/',
  panther: 'https://dex.pantherswap.com/#/swap/',
  ape: 'https://app.apeswap.finance/swap/',
  'wault-poly': 'https://swap.wault.finance/polygon/index.html#/swap/',
  polycrystal: 'https://dex.polycrystal.finance/#/swap/',
  baby: 'https://exchange.babyswap.finance/#/swap/',
  jet: 'https://exchange.jetswap.finance/#/swap/',
  nova: 'https://swap.shibanova.io/#/swap/',
  honey: 'https://pancakeswap.finance/swap/',
  autoshark: 'https://autoshark.finance/swap/',
}

const vaults: VaultConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
   {
    pid: 1,
    lpSymbol: 'FINS-BNB',
    lpAddresses: {
      97: '',
      56: '0x14B5a6d26577970953F9E6608d6604e4676Ac5b7',
    },
    token: tokens.fins,
    farmToken: tokens.fins,
    quoteToken: tokens.wbnb,
    vaultAddress: FINS_BNB_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    swap: 'autoshark',
    deprecated: false,
    boostRate: 1.55,
    maximizer: true,
    singleMaximizer: true,
    maximizerId: 1,
  },
  {
    pid: 2,
    lpSymbol: 'JAWS-BNB',
    lpAddresses: {
      97: '',
      56: '0x1BbA5C6Cc38EF04066042237b4298dC23405304D',
    },
    token: tokens.jaws,
    farmToken: tokens.fins,
    quoteToken: tokens.wbnb,
    vaultAddress: JAWS_BNB_JAWS_MAXIMIZER_VAULT_ERROR,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    swap: 'autoshark',
    deprecated: false,
    boostRate: 1.55,
    maximizer: true,
    singleMaximizer: true,
    maximizerId: 1,
    repeated: true,
  },
  {
    pid: 2,
    lpSymbol: 'JAWS-BNB',
    lpAddresses: {
      97: '',
      56: '0x1BbA5C6Cc38EF04066042237b4298dC23405304D',
    },
    token: tokens.jaws,
    farmToken: tokens.jaws,
    quoteToken: tokens.wbnb,
    vaultAddress: JAWS_BNB_JAWS_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: getOceanMasterChefAddress(),
    masterChefOutput: 'jawsPerBlock',
    swap: 'autoshark',
    deprecated: false,
    boostRate: 1.55,
    maximizer: true,
    singleMaximizer: true,
    maximizerId: 0,
  },
  {
    pid: 0,
    lpSymbol: 'JAWS',
    lpAddresses: {
      97: '',
      56: '0x9348B45c542db53FE750F01F014BF6ae04b1395a',
    },
    token: tokens.jaws,
    farmToken: tokens.jaws,
    quoteToken: tokens.wbnb,
    vaultAddress: JAWS_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: CAKE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'autoshark',
    singleStake: true,
    deprecated: false,
    boostRate: 1.325,
    whitelisted: true,
    singleMaximizer: true,
    core: true,
    burning: true,
  },
  {
    pid: 1,
    lpSymbol: 'FINS',
    lpAddresses: {
      97: '',
      56: '0x14B5a6d26577970953F9E6608d6604e4676Ac5b7',
    },
    token: tokens.fins,
    farmToken: tokens.fins,
    quoteToken: tokens.wbnb,
    vaultAddress: FINS_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    swap: 'autoshark',
    singleStake: true,
    deprecated: false,
    boostRate: 1.25,
    whitelisted: true,
    singleMaximizer: true,
    core: true,
    burning: true,
  },
  {
    pid: 0,
    lpSymbol: 'CAKE',
    lpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    token: tokens.cake,
    farmToken: tokens.fins,
    quoteToken: tokens.wbnb,
    vaultAddress: CAKE_TO_FINS_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: CAKE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'autoshark',
    singleStake: true,
    deprecated: false,
    boostRate: 2.7,
    whitelisted: true,
    singleMaximizer: true,
    core: true,
    apex: true,
  },
  {
    pid: 3,
    lpSymbol: 'SQUID',
    lpAddresses: {
      97: '',
      56: '0x2e0484D3684701dC032f29cce59c785A5837B34E',
    },
    token: tokens.squid,
    farmToken: tokens.squid,
    quoteToken: tokens.wbnb,
    vaultAddress: SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    singleStake: true,
    deprecated: true,
    boostRate: 0.65,
    whitelisted: true,
    singleMaximizer: true,
    core: true,
    burning: true,
    apex: false,
    depositFees: 0,
    maximizerId: 3
  },
  {
    pid: 0,
    lpSymbol: 'BANANA',
    lpAddresses: {
      97: '',
      56: '0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713',
    },
    token: tokens.banana,
    farmToken: tokens.fins,
    quoteToken: tokens.wbnb,
    vaultAddress: BANANA_TO_FINS_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: APE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'autoshark',
    singleStake: true,
    deprecated: false,
    boostRate: 2.3,
    whitelisted: true,
    singleMaximizer: true,
    core: true,
    apex: true,
  },
  {
    pid: 0,
    lpSymbol: 'BSW',
    lpAddresses: {
      97: '',
      56: '0x46492B26639Df0cda9b2769429845cb991591E0A',
    },
    token: tokens.bsw,
    farmToken: tokens.fins,
    quoteToken: tokens.wbnb,
    vaultAddress: BSW_TO_FINS_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: BISWAP_MASTER_CHEF,
    masterChefOutput: 'BSWPerBlock',
    swap: 'autoshark',
    singleStake: true,
    deprecated: false,
    boostRate: 2,
    whitelisted: true,
    singleMaximizer: true,
    core: true,
    apex: true,
  },
  {
    pid: 0,
    lpSymbol: 'BABY',
    lpAddresses: {
      97: '',
      56: '0x36aE10A4d16311959b607eE98Bc4a8A653A33b1F',
    },
    token: tokens.baby,
    farmToken: tokens.fins,
    quoteToken: tokens.wbnb,
    vaultAddress: BABY_TO_FINS_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: BABY_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'autoshark',
    singleStake: true,
    deprecated: false,
    boostRate: 2,
    whitelisted: true,
    singleMaximizer: true,
    core: true,
    apex: true,
  },
  // {
  //   pid: 0,
  //   lpSymbol: 'WBNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   },
  //   token: tokens.wbnb,
  //   farmToken: tokens.fins,
  //   quoteToken: tokens.wbnb,
  //   vaultAddress: SINGLE_BNB_VAULT,
  //   calculator: PRICE_CALCULATOR_BSC,
  //   dashboard: DASHBOARD_BSC,
  //   masterChef: BABY_MASTER_CHEF,
  //   masterChefOutput: 'cakePerBlock',
  //   fairLaunch: ALPACA_FAIR_LAUNCH,
  //   ibToken: "0xd7D069493685A581d27824Fc46EdA46B7EfC0063",
  //   swap: 'autoshark',
  //   singleStake: true,
  //   isFairLaunch: true,
  //   singleMaximizer: true,
  //   deprecated: false,
  //   boostRate: 2.5,
  //   whitelisted: true,
  //   maximizerId: 1,
  //   core: false,
  //   apex: true,
  // },
  // {
  //   pid: 2,
  //   lpSymbol: 'PIRATE',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xf8D928f18d8aDc074c364C49732e9680f8F82179',
  //   },
  //   token: tokens.pirate,
  //   farmToken: tokens.pirate,
  //   quoteToken: tokens.wbnb,
  //   vaultAddress: PIRATE_MAXIMIZER_VAULT,
  //   calculator: PRICE_CALCULATOR_BSC,
  //   dashboard: DASHBOARD_BSC,
  //   masterChef: FINS_MASTER_CHEF,
  //   masterChefOutput: 'finsPerBlock',
  //   swap: 'autoshark',
  //   singleStake: true,
  //   deprecated: false,
  //   boostRate: 1.15,
  //   whitelisted: true,
  //   singleMaximizer: true,
  //   core: true,
  // },
  {
    pid: 2,
    lpSymbol: 'JAWS-BNB',
    lpAddresses: {
      97: '',
      56: '0x1BbA5C6Cc38EF04066042237b4298dC23405304D',
    },
    token: tokens.jaws,
    farmToken: tokens.fins,
    quoteToken: tokens.wbnb,
    vaultAddress: JAWS_BNB_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    singleMaximizer: true,
    maximizerId: 1,
  },
  {
    pid: 1,
    lpSymbol: 'SQUID-BNB',
    lpAddresses: {
      97: '',
      56: '0x2e0484D3684701dC032f29cce59c785A5837B34E',
    },
    token: tokens.squid,
    farmToken: tokens.squid,
    quoteToken: tokens.wbnb,
    vaultAddress: SQUID_BNB_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
  },
  {
    pid: 2,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0xD636EA3609615ad8Aa667509c50946098454EaD2',
    },
    token: tokens.wbnb,
    farmToken: tokens.squid,
    quoteToken: tokens.busd,
    vaultAddress: BNB_BUSD_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 3,
    lpSymbol: 'BUSD-USDT',
    lpAddresses: {
      97: '',
      56: '0x06347b91DaBc757cCD5A5AA2aF802cEc78e4D239',
    },
    token: tokens.busd,
    farmToken: tokens.squid,
    quoteToken: tokens.usdt,
    vaultAddress: BUSD_USDT_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 4,
    lpSymbol: 'USDT-USDC',
    lpAddresses: {
      97: '',
      56: '0x81E6Cd6AdeDA14D94993aa9D97193B7266001cD3',
    },
    token: tokens.usdc,
    farmToken: tokens.squid,
    quoteToken: tokens.usdt,
    vaultAddress: USDT_USDC_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 5,
    lpSymbol: 'CAKE-BNB',
    lpAddresses: {
      97: '',
      56: '0xCe39f9c2FffE30E093b54DcEa5bf45C727290985',
    },
    token: tokens.cake,
    farmToken: tokens.squid,
    quoteToken: tokens.wbnb,
    vaultAddress: CAKE_BNB_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 6,
    lpSymbol: 'BTCB-BNB',
    lpAddresses: {
      97: '',
      56: '0x51d267099Ccc9C67A5B4A69e3bb71693dffF29Ed',
    },
    token: tokens.btcb,
    farmToken: tokens.squid,
    quoteToken: tokens.wbnb,
    vaultAddress: BTCB_BNB_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 7,
    lpSymbol: 'ETH-BNB',
    lpAddresses: {
      97: '',
      56: '0x3ce41b541553AafB795Dfc27C4c9271772515aFF',
    },
    token: tokens.eth,
    farmToken: tokens.squid,
    quoteToken: tokens.wbnb,
    vaultAddress: ETH_BNB_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 8,
    lpSymbol: 'USDT-BNB',
    lpAddresses: {
      97: '',
      56: '0xBbbB885789Bcc958DA3de78748E0f40510d6a99b',
    },
    token: tokens.usdt,
    farmToken: tokens.squid,
    quoteToken: tokens.wbnb,
    vaultAddress: USDT_BNB_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 9,
    lpSymbol: 'USDC-BNB',
    lpAddresses: {
      97: '',
      56: '0x84d455d0b1FFb0b488CBD1f4b098f076e5628065',
    },
    token: tokens.usdc,
    farmToken: tokens.squid,
    quoteToken: tokens.wbnb,
    vaultAddress: USDC_BNB_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 10,
    lpSymbol: 'BTCB-ETH',
    lpAddresses: {
      97: '',
      56: '0x3942940EfeEF8C6435b722082beB3Eb2837EBD69',
    },
    token: tokens.btcb,
    farmToken: tokens.squid,
    quoteToken: tokens.eth,
    vaultAddress: BTCB_ETH_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 11,
    lpSymbol: 'LINK-BNB',
    lpAddresses: {
      97: '',
      56: '0xA7aBA4AE7881f482a0D2D132d6e4DC985f3cFa24',
    },
    token: tokens.link,
    farmToken: tokens.squid,
    quoteToken: tokens.wbnb,
    vaultAddress: LINK_BNB_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 12,
    lpSymbol: 'DOT-BNB',
    lpAddresses: {
      97: '',
      56: '0x7E7e1421a66fcf66e145d1ef204A517eB217B1a0',
    },
    token: tokens.dot,
    farmToken: tokens.squid,
    quoteToken: tokens.wbnb,
    vaultAddress: DOT_BNB_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 13,
    lpSymbol: 'SOL-BNB',
    lpAddresses: {
      97: '',
      56: '0x9d5B48AD38748c6DBD77399eccE3FD8B6f980456',
    },
    token: tokens.sol,
    farmToken: tokens.squid,
    quoteToken: tokens.wbnb,
    vaultAddress: SOL_BNB_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 14,
    lpSymbol: 'ADA-BNB',
    lpAddresses: {
      97: '',
      56: '0xAc246B90AA9F5D372D9722E32119E364CA5Ad77A',
    },
    token: tokens.ada,
    farmToken: tokens.squid,
    quoteToken: tokens.wbnb,
    vaultAddress: ADA_BNB_SQUID_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: SQUID_MASTER_CHEF,
    masterChefOutput: 'squidPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    depositFees: 3,
  },
  {
    pid: 5,
    lpSymbol: 'CAKE-BNB',
    lpAddresses: {
      97: '',
      56: '0xCe39f9c2FffE30E093b54DcEa5bf45C727290985',
    },
    token: tokens.cake,
    farmToken: tokens.fins,
    quoteToken: tokens.wbnb,
    vaultAddress: CAKE_BNB_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    swap: 'autoshark',
    deprecated: false,
    boostRate: 1.55,
    maximizer: true,
    singleMaximizer: true,
    maximizerId: 1,
  },
  {
    pid: 11,
    lpSymbol: 'PIRATE-BNB',
    lpAddresses: {
      97: '',
      56: '0x4A69AEcc8D54f4C547388f5B5E60AC525B868275',
    },
    token: tokens.pirate,
    farmToken: tokens.fins,
    quoteToken: tokens.wbnb,
    vaultAddress: PIRATE_BNB_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    singleMaximizer: true,
    maximizerId: 1,
  },
  {
    pid: 3,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0xD636EA3609615ad8Aa667509c50946098454EaD2',
    },
    token: tokens.wbnb,
    farmToken: tokens.fins,
    quoteToken: tokens.busd,
    vaultAddress: BNB_BUSD_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    swap: 'autoshark',
    deprecated: false,
    boostRate: 1.55,
    maximizer: true,
    singleMaximizer: true,
    maximizerId: 1,
  },
  {
    pid: 4,
    lpSymbol: 'BUSD-USDT',
    lpAddresses: {
      97: '',
      56: '0x06347b91DaBc757cCD5A5AA2aF802cEc78e4D239',
    },
    token: tokens.busd,
    farmToken: tokens.fins,
    quoteToken: tokens.usdt,
    vaultAddress: BUSD_USDT_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    swap: 'autoshark',
    deprecated: false,
    boostRate: 1.55,
    maximizer: true,
    singleMaximizer: true,
    maximizerId: 1,
  },
  {
    pid: 15,
    lpSymbol: 'BNB-USDT',
    lpAddresses: {
      97: '',
      56: '0xBbbB885789Bcc958DA3de78748E0f40510d6a99b',
    },
    token: tokens.wbnb,
    farmToken: tokens.fins,
    quoteToken: tokens.usdt,
    vaultAddress: BNB_USDT_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    swap: 'autoshark',
    deprecated: false,
    boostRate: 1.55,
    maximizer: true,
    singleMaximizer: true,
    maximizerId: 1,
  },
  {
    pid: 10,
    lpSymbol: 'USDT-USDC',
    lpAddresses: {
      97: '',
      56: '0x81E6Cd6AdeDA14D94993aa9D97193B7266001cD3',
    },
    token: tokens.usdc,
    farmToken: tokens.fins,
    quoteToken: tokens.usdt,
    vaultAddress: USDT_USDC_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    swap: 'autoshark',
    deprecated: false,
    boostRate: 1.55,
    maximizer: true,
    singleMaximizer: true,
    maximizerId: 1,
  },
  {
    pid: 9,
    lpSymbol: 'DAI-USDT',
    lpAddresses: {
      97: '',
      56: '0xbD78f8Cf00e03C00afAf684B72D08CF8F997d4Bb',
    },
    token: tokens.dai,
    farmToken: tokens.fins,
    quoteToken: tokens.usdt,
    vaultAddress: DAI_USDT_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    swap: 'autoshark',
    deprecated: true,
    boostRate: 1.55,
    maximizer: true,
    singleMaximizer: true,
    maximizerId: 1,
  },
  {
    pid: 25,
    lpSymbol: 'BTC-ETH',
    lpAddresses: {
      97: '',
      56: '0x3942940EfeEF8C6435b722082beB3Eb2837EBD69',
    },
    token: tokens.btcb,
    farmToken: tokens.fins,
    quoteToken: tokens.eth,
    vaultAddress: BTC_ETH_MAXIMIZER_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'finsPerBlock',
    swap: 'autoshark',
    deprecated: false,
    boostRate: 1.55,
    maximizer: true,
    singleMaximizer: true,
    maximizerId: 1,
  },

  {
    pid: 0,
    lpSymbol: 'WBNB',
    lpAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    token: tokens.wbnb,
    farmToken: tokens.wbnb,
    quoteToken: tokens.wbnb,
    vaultAddress: SINGLE_BNB_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'alpacaPerBlock',
    fairLaunch: ALPACA_FAIR_LAUNCH,
    ibToken: '0xd7D069493685A581d27824Fc46EdA46B7EfC0063',
    swap: 'autoshark',
    singleStake: true,
    isFairLaunch: true,
    singleMaximizer: true,
    deprecated: false,
    boostRate: 2,
    whitelisted: true,
    core: false,
    apex: false,
  },
  {
    pid: 0,
    lpSymbol: 'BUSD',
    lpAddresses: {
      97: '',
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    },
    token: tokens.busd,
    farmToken: tokens.busd,
    quoteToken: tokens.busd,
    vaultAddress: SINGLE_BUSD_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'alpacaPerBlock',
    fairLaunch: ALPACA_FAIR_LAUNCH,
    ibToken: '0x7C9e73d4C71dae564d41F78d56439bB4ba87592f',
    swap: 'autoshark',
    singleStake: true,
    isFairLaunch: true,
    singleMaximizer: true,
    deprecated: false,
    boostRate: 2,
    whitelisted: true,
    core: false,
    apex: false,
  },
  {
    pid: 0,
    lpSymbol: 'BTCB',
    lpAddresses: {
      97: '',
      56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    },
    token: tokens.btcb,
    farmToken: tokens.btcb,
    quoteToken: tokens.btcb,
    vaultAddress: SINGLE_BTCB_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'alpacaPerBlock',
    fairLaunch: ALPACA_FAIR_LAUNCH,
    ibToken: '0x08FC9Ba2cAc74742177e0afC3dC8Aed6961c24e7',
    swap: 'autoshark',
    singleStake: true,
    isFairLaunch: true,
    singleMaximizer: true,
    deprecated: false,
    boostRate: 9,
    whitelisted: true,
    core: false,
    apex: false,
  },
  {
    pid: 0,
    lpSymbol: 'ETH',
    lpAddresses: {
      97: '',
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    token: tokens.eth,
    farmToken: tokens.eth,
    quoteToken: tokens.eth,
    vaultAddress: SINGLE_ETH_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: FINS_MASTER_CHEF,
    masterChefOutput: 'alpacaPerBlock',
    fairLaunch: ALPACA_FAIR_LAUNCH,
    ibToken: '0xbfF4a34A4644a113E8200D7F1D79b3555f723AfE',
    swap: 'autoshark',
    singleStake: true,
    isFairLaunch: true,
    singleMaximizer: true,
    deprecated: false,
    boostRate: 5,
    whitelisted: true,
    core: false,
    apex: false,
  },

  // {
  //   pid: 8,
  //   lpSymbol: 'BTC-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x51d267099Ccc9C67A5B4A69e3bb71693dffF29Ed',
  //   },
  //   token: tokens.bnb,
  //   farmToken: tokens.fins,
  //   quoteToken: tokens.btcb,
  //   vaultAddress: BTC_BNB_MAXIMIZER_VAULT,
  //   calculator: PRICE_CALCULATOR_BSC,
  //   dashboard: DASHBOARD_BSC,
  //   masterChef: FINS_MASTER_CHEF,
  //   masterChefOutput: 'finsPerBlock',
  //   swap: 'autoshark',
  //   deprecated: false,
  //   boostRate: 1.55,
  //   maximizer: true,
  //   singleMaximizer: true,
  //   maximizerId: 1,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'CAKE-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xCe39f9c2FffE30E093b54DcEa5bf45C727290985',
  //   },
  //   token: tokens.cake,
  //   farmToken: tokens.fins,
  //   quoteToken: tokens.bnb,
  //   vaultAddress: CAKE_BNB_MAXIMIZER_VAULT,
  //   calculator: PRICE_CALCULATOR_BSC,
  //   dashboard: DASHBOARD_BSC,
  //   masterChef: FINS_MASTER_CHEF,
  //   masterChefOutput: 'finsPerBlock',
  //   swap: 'autoshark',
  //   deprecated: false,
  //   boostRate: 1.55,
  //   maximizer: true,
  //   singleMaximizer: true,
  //   maximizerId: 1,
  // },
  // {
  //   pid: 7,
  //   lpSymbol: 'ETH-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x3ce41b541553AafB795Dfc27C4c9271772515aFF',
  //   },
  //   token: tokens.eth,
  //   farmToken: tokens.fins,
  //   quoteToken: tokens.bnb,
  //   vaultAddress: ETH_BNB_MAXIMIZER_VAULT,
  //   calculator: PRICE_CALCULATOR_BSC,
  //   dashboard: DASHBOARD_BSC,
  //   masterChef: FINS_MASTER_CHEF,
  //   masterChefOutput: 'finsPerBlock',
  //   swap: 'autoshark',
  //   deprecated: false,
  //   boostRate: 1.55,
  //   maximizer: true,
  //   singleMaximizer: true,
  //   maximizerId: 1,
  // },
  // {
  //   pid: 6,
  //   lpSymbol: 'LINK-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xA7aBA4AE7881f482a0D2D132d6e4DC985f3cFa24',
  //   },
  //   token: tokens.link,
  //   farmToken: tokens.fins,
  //   quoteToken: tokens.bnb,
  //   vaultAddress: LINK_BNB_MAXIMIZER_VAULT,
  //   calculator: PRICE_CALCULATOR_BSC,
  //   dashboard: DASHBOARD_BSC,
  //   masterChef: FINS_MASTER_CHEF,
  //   masterChefOutput: 'finsPerBlock',
  //   swap: 'autoshark',
  //   deprecated: false,
  //   boostRate: 1.55,
  //   maximizer: true,
  //   singleMaximizer: true,
  //   maximizerId: 1,
  // },
  {
    pid: 0,
    lpSymbol: 'CAKE',
    lpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    token: tokens.cake,
    farmToken: tokens.cake,
    quoteToken: tokens.wbnb,
    vaultAddress: CAKE_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: CAKE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'pancake',
    singleStake: true,
    deprecated: false,
    boostRate: 1.69,
    whitelisted: true,
  },
  {
    pid: 0,
    lpSymbol: 'CAKE',
    lpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    token: tokens.cake,
    farmToken: tokens.cake,
    quoteToken: tokens.wbnb,
    vaultAddress: COMPENSATION_CAKE_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: CAKE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'pancake',
    singleStake: true,
    deprecated: false,
    boostRate: 1.89,
    whitelisted: true,
  },
  {
    pid: 0,
    lpSymbol: 'BABY',
    lpAddresses: {
      97: '',
      56: '0x36aE10A4d16311959b607eE98Bc4a8A653A33b1F',
    },
    token: tokens.baby,
    farmToken: tokens.baby,
    quoteToken: tokens.wbnb,
    vaultAddress: BABY_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: BABY_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'baby',
    singleStake: true,
    deprecated: true,
    boostRate: 1.4,
  },
  {
    pid: 28,
    lpSymbol: 'BABY-USDT',
    lpAddresses: {
      97: '',
      56: '0xE730C7B7470447AD4886c763247012DfD233bAfF',
    },
    token: tokens.baby,
    farmToken: tokens.baby,
    quoteToken: tokens.usdt,
    vaultAddress: BABY_USDT_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: BABY_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'baby',
    singleStake: false,
    deprecated: false,
    boostRate: 1.6,
    whitelisted: true,
  },
  {
    pid: 47,
    lpSymbol: 'BABY-BNB',
    lpAddresses: {
      97: '',
      56: '0x36aE10A4d16311959b607eE98Bc4a8A653A33b1F',
    },
    token: tokens.baby,
    farmToken: tokens.baby,
    quoteToken: tokens.wbnb,
    vaultAddress: BABY_BNB_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: BABY_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'baby',
    singleStake: false,
    deprecated: false,
    boostRate: 1.6,
    whitelisted: true,
  },
  {
    pid: 70,
    lpSymbol: 'JAWS-USDT',
    lpAddresses: {
      97: '',
      56: '0xeC1F0013341A85872987431cFf2fFf424326Adf5',
    },
    token: tokens.jaws,
    farmToken: tokens.baby,
    quoteToken: tokens.usdt,
    vaultAddress: JAWS_USDT_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: BABY_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'baby',
    singleStake: false,
    deprecated: true,
    boostRate: 1.5,
    whitelisted: true,
  },
  {
    pid: 55,
    lpSymbol: 'PIRATE-USDT',
    lpAddresses: {
      97: '',
      56: '0x7E7E0ef69655F6B726dFD9602cd7661af61dBd01',
    },
    token: tokens.pirate,
    farmToken: tokens.baby,
    quoteToken: tokens.usdt,
    vaultAddress: PIRATE_USDT_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: BABY_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'baby',
    singleStake: false,
    deprecated: true,
    boostRate: 1.5,
    whitelisted: true,
  },
  {
    pid: 85,
    lpSymbol: 'WOLFIES-USDT',
    lpAddresses: {
      97: '',
      56: '0xb4f23a2402ffB08Eb3c8f5B9122E932e75924239',
    },
    token: tokens.wolfies,
    farmToken: tokens.baby,
    quoteToken: tokens.usdt,
    vaultAddress: WOLFIES_USDT_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: BABY_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'baby',
    singleStake: false,
    deprecated: true,
    boostRate: 1.5,
    whitelisted: true,
  },
  {
    pid: 75,
    lpSymbol: 'GUARD-USDT',
    lpAddresses: {
      97: '',
      56: '0xE69EF9fE5592Db9ECeC5491922Db0F95418Eb4bd',
    },
    token: tokens.guard,
    farmToken: tokens.baby,
    quoteToken: tokens.usdt,
    vaultAddress: GUARD_USDT_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: BABY_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'baby',
    singleStake: false,
    deprecated: true,
    boostRate: 1.5,
    whitelisted: true,
  },
  {
    pid: 40,
    lpSymbol: 'MOON-BNB',
    lpAddresses: {
      97: '',
      56: '0x643D3f1C1fA1A7D5D6BDE50Bd3FDD1Cdd8A85692',
    },
    token: tokens.moon,
    farmToken: tokens.moon,
    quoteToken: tokens.wbnb,
    vaultAddress: MOON_BNB_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: HONEY_MASTER_CHEF,
    proxyChef: '0x2D25390439c59615d0240c558e9801Ca00490ca4',
    masterChefOutput: 'EarningsPerBlock',
    swap: 'honey',
    singleStake: false,
    deprecated: true,
    boostRate: 1.2,
    whitelisted: true,
  },
  {
    pid: 0,
    lpSymbol: 'BEAR-BNB',
    lpAddresses: {
      97: '',
      56: '0xC5E96F03414DA6E7eA6D2F051D23964C0B80739F',
    },
    token: tokens.bear,
    farmToken: tokens.moon,
    quoteToken: tokens.wbnb,
    vaultAddress: BEAR_BNB_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: HONEY_MASTER_CHEF,
    proxyChef: '0xe2B7A7Eb8c35682396AB89e415b256064f5c85A9',
    masterChefOutput: 'EarningsPerBlock',
    swap: 'honey',
    singleStake: false,
    deprecated: true,
    boostRate: 1.2,
    whitelisted: true,
  },
  {
    pid: 1,
    lpSymbol: 'BEAR-BUSD',
    lpAddresses: {
      97: '',
      56: '0x9DD10B5d23A05bBAC9E9Ae0C7ca9890791eABb25',
    },
    token: tokens.bear,
    farmToken: tokens.moon,
    quoteToken: tokens.busd,
    vaultAddress: BEAR_BUSD_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: HONEY_MASTER_CHEF,
    proxyChef: '0x110bEeEBd969E90F9785F16052735dF9Fe51E630',
    masterChefOutput: 'EarningsPerBlock',
    swap: 'honey',
    singleStake: false,
    deprecated: true,
    boostRate: 1.2,
    whitelisted: true,
  },
  {
    pid: 2,
    lpSymbol: 'HONEY-BNB',
    lpAddresses: {
      97: '',
      56: '0x27418bf2E38E613560D2281dB6590f3f6D446259',
    },
    token: tokens.honey,
    farmToken: tokens.moon,
    quoteToken: tokens.wbnb,
    vaultAddress: HONEY_BNB_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: HONEY_MASTER_CHEF,
    proxyChef: '0x0F13C3825Ab5eB85170c0E4d3Dc8CD4d5952AB91',
    masterChefOutput: 'EarningsPerBlock',
    swap: 'honey',
    singleStake: false,
    deprecated: true,
    boostRate: 1.2,
    whitelisted: true,
  },
  {
    pid: 3,
    lpSymbol: 'HONEY-BUSD',
    lpAddresses: {
      97: '',
      56: '0x2961aa26a1Cb3068E580099045cc79a5b7B9634c',
    },
    token: tokens.honey,
    farmToken: tokens.moon,
    quoteToken: tokens.busd,
    vaultAddress: HONEY_BUSD_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: HONEY_MASTER_CHEF,
    proxyChef: '0xBDE0b81ABd4156705a26829Ecd9789187CA0D35d',
    masterChefOutput: 'EarningsPerBlock',
    swap: 'honey',
    singleStake: false,
    deprecated: true,
    boostRate: 1.2,
    whitelisted: true,
  },
  {
    pid: 0,
    lpSymbol: 'BANANA',
    lpAddresses: {
      97: '',
      56: '0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713',
    },
    token: tokens.banana,
    farmToken: tokens.banana,
    quoteToken: tokens.wbnb,
    vaultAddress: BANANA_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: APE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'ape',
    singleStake: true,
    deprecated: false,
    boostRate: 1.5,
    whitelisted: true,
  },
  {
    pid: 1,
    lpSymbol: 'BANANA-BNB',
    lpAddresses: {
      97: '',
      56: '0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713',
    },
    token: tokens.banana,
    farmToken: tokens.banana,
    quoteToken: tokens.wbnb,
    vaultAddress: BANANA_BNB_APE_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: APE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'ape',
    singleStake: false,
    deprecated: false,
    boostRate: 1.5,
    whitelisted: true,
  },
  {
    pid: 2,
    lpSymbol: 'BANANA-BUSD',
    lpAddresses: {
      97: '',
      56: '0x7Bd46f6Da97312AC2DBD1749f82E202764C0B914',
    },
    token: tokens.banana,
    farmToken: tokens.banana,
    quoteToken: tokens.busd,
    vaultAddress: BANANA_BUSD_APE_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: APE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'ape',
    singleStake: false,
    deprecated: false,
    boostRate: 1.5,
    whitelisted: true,
  },
  {
    pid: 111,
    lpSymbol: 'WIZARD-WBNB',
    lpAddresses: {
      97: '',
      56: '0xF258A201EE142C1fdeb0C29a2C9941779fDfCE0E',
    },
    token: tokens.wizard,
    farmToken: tokens.banana,
    quoteToken: tokens.wbnb,
    vaultAddress: WIZARD_WBNB_APE_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: APE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'ape',
    singleStake: false,
    deprecated: true,
    boostRate: 1.2,
    whitelisted: true,
  },
  {
    pid: 120,
    lpSymbol: 'DEP-WBNB',
    lpAddresses: {
      97: '',
      56: '0x6518709dC42F70Dbf89934cA382F1d9dB26370e8',
    },
    token: tokens.dep,
    farmToken: tokens.banana,
    quoteToken: tokens.wbnb,
    vaultAddress: DEP_WBNB_APE_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: APE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'ape',
    singleStake: false,
    deprecated: true,
    boostRate: 1.2,
    whitelisted: true,
  },
  {
    pid: 92,
    lpSymbol: 'SISTA-BNB',
    lpAddresses: {
      97: '',
      56: '0x45546310FDA2fbDB7Ee26EA0A5b6F82D075254bC',
    },
    token: tokens.sista,
    farmToken: tokens.banana,
    quoteToken: tokens.wbnb,
    vaultAddress: SISTA_WBNB_APE_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: APE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'ape',
    singleStake: false,
    deprecated: true,
    boostRate: 1.2,
    whitelisted: true,
  },
  {
    pid: 251,
    lpSymbol: 'CAKE-BNB',
    lpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    token: tokens.cake,
    farmToken: tokens.cake,
    quoteToken: tokens.wbnb,
    vaultAddress: CAKE_BNB_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: CAKE_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'pancake',
    // singleStake: true,
    deprecated: false,
    boostRate: 2.27,
    maximizer: true,
  },
  {
    pid: 0,
    lpSymbol: 'NOVA',
    lpAddresses: {
      97: '',
      56: '0xfB240baf7b6308D8Ba56Bf6a181fA8AbC39a1df1',
    },
    token: tokens.nova,
    farmToken: tokens.nova,
    quoteToken: tokens.wbnb,
    vaultAddress: SHIBA_NOVA_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: NOVA_MASTER_CHEF,
    masterChefOutput: 'NovaPerBlock',
    swap: 'nova',
    singleStake: true,
    deprecated: true,
    boostRate: 1.2,
    whitelisted: true,
  },
  {
    pid: 1,
    lpSymbol: 'NOVA-BNB',
    lpAddresses: {
      97: '',
      56: '0xfB240baf7b6308D8Ba56Bf6a181fA8AbC39a1df1',
    },
    token: tokens.nova,
    farmToken: tokens.nova,
    quoteToken: tokens.wbnb,
    vaultAddress: SHIBA_NOVA_BNB_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: NOVA_MASTER_CHEF,
    masterChefOutput: 'NovaPerBlock',
    swap: 'nova',
    singleStake: false,
    deprecated: true,
    boostRate: 1.2,
    whitelisted: true,
  },
  {
    pid: 0,
    lpSymbol: 'WINGS',
    lpAddresses: {
      97: '',
      56: '0x1CD0fe829d83Fb49c8831Ae860d19c6062adA6e9',
    },
    token: tokens.wings,
    farmToken: tokens.wings,
    quoteToken: tokens.wbnb,
    vaultAddress: WINGS_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: JET_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'jet',
    singleStake: true,
    deprecated: true,
    boostRate: 1.2,
  },
  {
    pid: 3,
    lpSymbol: 'WINGS-BNB',
    lpAddresses: {
      97: '',
      56: '0x1CD0fe829d83Fb49c8831Ae860d19c6062adA6e9',
    },
    token: tokens.wings,
    farmToken: tokens.wings,
    quoteToken: tokens.wbnb,
    vaultAddress: WINGS_BNB_JET_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: JET_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'jet',
    deprecated: true,
    boostRate: 1.2,
  },
  {
    pid: 4,
    lpSymbol: 'WINGS-BUSD',
    lpAddresses: {
      97: '',
      56: '0xFBa740304f3fc39d0e79703a5D7788E13f877DC0',
    },
    token: tokens.wings,
    farmToken: tokens.wings,
    quoteToken: tokens.busd,
    vaultAddress: WINGS_BUSD_JET_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: JET_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'jet',
    deprecated: true,
    boostRate: 1.2,
  },
  // {
  //   pid: 6,
  //   lpSymbol: 'FTS-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xBd22832367bCb71b8d2030cF3110ec2F9b63a240',
  //   },
  //   token: tokens.fts,
  //   farmToken: tokens.wings,
  //   quoteToken: tokens.wbnb,
  //   vaultAddress: FTS_BNB_JET_VAULT,
  //   calculator: PRICE_CALCULATOR_BSC,
  //   dashboard: DASHBOARD_BSC,
  //   masterChef: JET_MASTER_CHEF,
  //   masterChefOutput: 'cakePerBlock',
  //   swap: 'jet',
  //   deprecated: false,
  //   boostRate: 1.2,
  // },
  {
    pid: 7,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0xc53eE6446101F2128f887b8c75083E74dca3e973',
    },
    token: tokens.wbnb,
    farmToken: tokens.wings,
    quoteToken: tokens.busd,
    vaultAddress: BNB_BUSD_JET_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: JET_MASTER_CHEF,
    masterChefOutput: 'cakePerBlock',
    swap: 'jet',
    deprecated: true,
    boostRate: 1.2,
  },
  {
    pid: 0,
    lpSymbol: 'BSW',
    lpAddresses: {
      97: '',
      56: '0x46492B26639Df0cda9b2769429845cb991591E0A',
    },
    token: tokens.bsw,
    farmToken: tokens.bsw,
    quoteToken: tokens.bsw,
    vaultAddress: BISWAP_VAULT_V3,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: BISWAP_MASTER_CHEF,
    masterChefOutput: 'BSWPerBlock',
    swap: 'biswap',
    singleStake: true,
    deprecated: false,
    boostRate: 1,
  },
  {
    pid: 53,
    lpSymbol: 'JAWS-BNB',
    lpAddresses: {
      97: '',
      56: '0xA162A6443bd39bfC0E0ceFfb0b1BBf92240D051C',
    },
    token: tokens.jaws,
    farmToken: tokens.wex,
    quoteToken: tokens.wbnb,
    vaultAddress: JAWS_BNB_WAULT_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: WEX_MASTER_CHEF,
    masterChefOutput: 'wexPerBlock',
    swap: 'wault',
    deprecated: true,
    boostRate: 2,
  },
  {
    pid: 51,
    lpSymbol: 'PIRATE-BNB',
    lpAddresses: {
      97: '',
      56: '0xf8D928f18d8aDc074c364C49732e9680f8F82179',
    },
    token: tokens.pirate,
    farmToken: tokens.wex,
    quoteToken: tokens.wbnb,
    vaultAddress: PIRATE_BNB_WAULT_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: WEX_MASTER_CHEF,
    masterChefOutput: 'wexPerBlock',
    swap: 'wault',
    deprecated: true,
    boostRate: 2,
  },
  {
    pid: 3,
    lpSymbol: 'WEX',
    lpAddresses: {
      97: '',
      56: '0xb4814f1ca673dBE9a2637C5dd4e94A4a0ED834C6',
    },
    token: tokens.wex,
    farmToken: tokens.wex,
    quoteToken: tokens.wbnb,
    vaultAddress: WEX_VAULT_V3,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: WEX_MASTER_CHEF,
    masterChefOutput: 'wexPerBlock',
    swap: 'wault',
    singleStake: true,
    deprecated: true,
    boostRate: 2,
  },
  {
    pid: 58,
    lpSymbol: 'WUSD-BUSD',
    lpAddresses: {
      97: '',
      56: '0x6102D8A7C963F78D46a35a6218B0DB4845d1612F',
    },
    token: tokens.wusd,
    farmToken: tokens.wex,
    quoteToken: tokens.busd,
    vaultAddress: WUSD_BUSD_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: WEX_MASTER_CHEF,
    masterChefOutput: 'wexPerBlock',
    swap: 'wault',
    singleStake: false,
    deprecated: true,
    boostRate: 2,
  },
  {
    pid: 60,
    lpSymbol: 'WIZARD-WUSD',
    lpAddresses: {
      97: '',
      56: '0x562f4D346d7464522D6350b546e22E38827418e2',
    },
    token: tokens.wizard,
    farmToken: tokens.wex,
    quoteToken: tokens.wusd,
    vaultAddress: WIZARD_WUSD_WAULT_VAULT,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: WEX_MASTER_CHEF,
    masterChefOutput: 'wexPerBlock',
    swap: 'wault',
    singleStake: false,
    deprecated: true,
    boostRate: 1.2,
  },
  {
    pid: 45,
    lpSymbol: 'JAWS-BNB',
    lpAddresses: {
      97: '',
      56: '0x0CC7984B1D6cb4c709A84e012c6d9CD4886e143d',
    },
    token: tokens.jaws,
    farmToken: tokens.panther,
    quoteToken: tokens.wbnb,
    vaultAddress: JAWS_BNB_FLIP_VAULT_V3,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: PANTHER_MASTER_CHEF,
    masterChefOutput: 'pantherPerBlock',
    swap: 'panther',
    deprecated: true,
    boostRate: 4.1,
  },
  {
    pid: 48,
    lpSymbol: 'PIRATE-BNB',
    lpAddresses: {
      97: '',
      56: '0x3F2FC02441fE78217F08A9B7a3c0107380025347',
    },
    token: tokens.pirate,
    farmToken: tokens.panther,
    quoteToken: tokens.wbnb,
    vaultAddress: PIRATE_BNB_POOL,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: PANTHER_MASTER_CHEF,
    masterChefOutput: 'pantherPerBlock',
    swap: 'panther',
    deprecated: true,
    boostRate: 1.99,
  },
  {
    pid: 44,
    lpSymbol: 'JAWS-BUSD',
    lpAddresses: {
      97: '',
      56: '0xa542d038888357Ed882713f3dEf707B2504D9c77',
    },
    token: tokens.jaws,
    farmToken: tokens.panther,
    quoteToken: tokens.busd,
    vaultAddress: JAWS_BUSD_FLIP_VAULT_V3,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: PANTHER_MASTER_CHEF,
    masterChefOutput: 'pantherPerBlock',
    swap: 'panther',
    deprecated: true,
    boostRate: 3.65,
  },
  {
    pid: 9,
    lpSymbol: 'PANTHER',
    lpAddresses: {
      97: '',
      56: '0xC24AD5197DaeFD97DF28C70AcbDF17d9fF92a49B',
    },
    token: tokens.panther,
    farmToken: tokens.panther,
    quoteToken: tokens.panther,
    vaultAddress: PANTHER_VAULT_V3,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: PANTHER_MASTER_CHEF,
    masterChefOutput: 'pantherPerBlock',
    swap: 'panther',
    deprecated: true,
    singleStake: true,
    boostRate: 1.25,
  },
  {
    pid: 17,
    lpSymbol: 'PANTHER-BNB',
    lpAddresses: {
      97: '',
      56: '0xC24AD5197DaeFD97DF28C70AcbDF17d9fF92a49B',
    },
    token: tokens.panther,
    farmToken: tokens.panther,
    quoteToken: tokens.wbnb,
    vaultAddress: PANTHER_BNB_FLIP_VAULT_V3,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: PANTHER_MASTER_CHEF,
    masterChefOutput: 'pantherPerBlock',
    swap: 'panther',
    deprecated: true,
    boostRate: 1.145,
  },
  {
    pid: 16,
    lpSymbol: 'PANTHER-BUSD',
    lpAddresses: {
      97: '',
      56: '0x9287F5Ad55D7eE8EAE90B865718EB9A7cF3fb71A',
    },
    token: tokens.panther,
    farmToken: tokens.panther,
    quoteToken: tokens.busd,
    vaultAddress: PANTHER_BUSD_FLIP_VAULT_V3,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: PANTHER_MASTER_CHEF,
    masterChefOutput: 'pantherPerBlock',
    swap: 'panther',
    deprecated: true,
    boostRate: 1.169,
  },
  {
    pid: 18,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0x6AF4C4433474B2F8BA385AD62B23299c82846783',
    },
    token: tokens.wbnb,
    farmToken: tokens.panther,
    quoteToken: tokens.busd,
    vaultAddress: BNB_BUSD_FLIP_VAULT_V3,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: PANTHER_MASTER_CHEF,
    masterChefOutput: 'pantherPerBlock',
    swap: 'panther',
    deprecated: true,
    boostRate: 1.35,
  },
  {
    pid: 25,
    lpSymbol: 'USDT-BUSD',
    lpAddresses: {
      97: '',
      56: '0x7EC7702d1e65C84470720563175A20c2fc03a72d',
    },
    token: tokens.usdt,
    farmToken: tokens.panther,
    quoteToken: tokens.busd,
    vaultAddress: USDT_BUSD_FLIP_VAULT_V3,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: PANTHER_MASTER_CHEF,
    masterChefOutput: 'pantherPerBlock',
    swap: 'panther',
    deprecated: true,
    boostRate: 1.4,
  },
  {
    pid: 20,
    lpSymbol: 'ETH-BNB',
    lpAddresses: {
      97: '',
      56: '0x542B077f6a06b488A31B8C8b31E456F2B3984989',
    },
    token: tokens.eth,
    farmToken: tokens.panther,
    quoteToken: tokens.wbnb,
    vaultAddress: ETH_BNB_FLIP_VAULT_V3,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: PANTHER_MASTER_CHEF,
    masterChefOutput: 'pantherPerBlock',
    swap: 'panther',
    deprecated: true,
    boostRate: 1.3,
  },
  {
    pid: 19,
    lpSymbol: 'BTCB-BNB',
    lpAddresses: {
      97: '',
      56: '0x9E78cEa62cD4DF80520912A137C0778Fe8a34186',
    },
    token: tokens.btcb,
    farmToken: tokens.panther,
    quoteToken: tokens.wbnb,
    vaultAddress: BTCB_BNB_FLIP_VAULT_V3,
    calculator: PRICE_CALCULATOR_BSC,
    dashboard: DASHBOARD_BSC,
    masterChef: PANTHER_MASTER_CHEF,
    masterChefOutput: 'pantherPerBlock',
    swap: 'panther',
    deprecated: true,
    boostRate: 1.45,
  },
]

export default vaults
