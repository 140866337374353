export const theme = {
  siteWidth: 1200,
  breakpoints: ['370px', '576px', '852px', '968px', '1080px'],
  mediaQueries: {
    xs: '@media screen and (min-width: 370px)',
    sm: '@media screen and (min-width: 576px)',
    md: '@media screen and (min-width: 852px)',
    lg: '@media screen and (min-width: 968px)',
    xl: '@media screen and (min-width: 1080px)',
    xxl: '@media screen and (min-width: 1372px)',
    nav: '@media screen and (min-width: 968px)',
  },
  spacing: [0, 4, 8, 16, 24, 32, 48, 64],
  shadows: {
    level1: '0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05)',
    active: '0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4)',
    success: '0px 0px 0px 1px #31D0AA, 0px 0px 0px 4px rgba(49, 208, 170, 0.2)',
    warning: '0px 0px 0px 1px #ED4B9E, 0px 0px 0px 4px rgba(237, 75, 158, 0.2)',
    focus: '0px 0px 0px 1px #d84165, 0px 0px 0px 4px rgba(216, 65, 10, 0.6)',
    inset: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
  },
  radii: { small: '4px', default: '14px', card: '14px', circle: '50%' },
  zIndices: { dropdown: 10, modal: 100 },
  isDark: true,
  alert: { background: '#191326' },
  colors: {
    failure: '#ED4B9E',
    primary: '#d84165',
    primaryBright: '#6898f2',
    primaryDark: '#4972bf',
    secondary: '#45e2ff',
    success: '#31D0AA',
    warning: '#FFB237',
    binance: '#F0B90B',
    overlay: '#2e8975',
    background: '#08060B',
    backgroundDisabled: '#3c3742',
    backgroundAlt: '#191326',
    cardBorder: '#383241',
    contrast: '#FFFFFF',
    dropdown: '#1E1D20',
    dropdownDeep: '#100C18',
    invertedContrast: '#191326',
    input: '#2c3857',
    inputSecondary: '#262130',
    tertiary: '#3a4f86',
    text: '#F4EEFF',
    textDisabled: '#666171',
    textSubtle: '#ffffff',
    disabled: '#524B63',
    gradients: {
      // bubblegum: 'linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)',
      // inverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
      bubblegum: 'linear-gradient(139.73deg, #152382 0%, #730b82 100%)',
      inverseBubblegum: 'linear-gradient(139.73deg, #730b82 0%, #152382 100%)',
      cardHeader: 'linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)',
      blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
      violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
      violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
      gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
    },
  },
  card: {
    background: '#191326',
    boxShadow: '0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05)',
    boxShadowActive: '0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4)',
    boxShadowSuccess: '0px 0px 0px 1px #31D0AA, 0px 0px 0px 4px rgba(49, 208, 170, 0.2)',
    boxShadowWarning: '0px 0px 0px 1px #ED4B9E, 0px 0px 0px 4px rgba(237, 75, 158, 0.2)',
    cardHeaderBackground: {
      default: 'linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)',
      blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
      // bubblegum: 'linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)',
      bubblegum: 'linear-gradient(139.73deg, #152382 0%, #730b82 100%)',
      violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
    },
    dropShadow: 'drop-shadow(0px 1px 4px rgba(25, 19, 38, 0.15))',
  },
  toggle: { handleBackground: '#191326' },
  nav: { background: '#101010' },
  modal: { background: '#191326' },
  pancakeToggle: { handleBackground: '#191326', handleShadow: '#666171' },
  radio: { handleBackground: '#191326' },
  tooltip: {
    background: '#FFFFFF',
    text: '#280D5F',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 4px 12px -8px rgba(14, 14, 44, 0.1)',
  },
}
