import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import fairlaunchABI from 'config/abi/fairlaunch.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { LendingConfig } from 'config/constants/types'

export const fetchVaultUserAllowances = async (account: string, vaultsToFetch: LendingConfig[]) => {
  const calls = vaultsToFetch.map((vault) => {
    const lpContractAddress = getAddress(vault.singleStake ? vault.token.address : vault.lpAddresses)
    return { address: lpContractAddress, name: 'allowance', params: [account, vault.masterChef] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchVaultUserTokenBalances = async (account: string, vaultsToFetch: LendingConfig[]) => {
  const calls = vaultsToFetch.map((vault) => {
    const lpContractAddress = getAddress(vault.singleStake ? vault.token.address : vault.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchVaultUserStakedBalances = async (account: string, vaultsToFetch: LendingConfig[]) => {
  const calls = vaultsToFetch.map((vault) => {
    return {
      address: vault.masterChef,
      name: vault.pendingOutput,
      params: [vault.pid, account],
    }
  })

  const rawStakedBalances = await multicall(fairlaunchABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchVaultUserEarnings = async (account: string, vaultsToFetch: LendingConfig[]) => {
  const calls = vaultsToFetch.map((vault) => {
    return {
      address: vault.masterChef,
      name: 'userInfo',
      params: [vault.pid, account],
    }
  })

  const rawStakedBalances = await multicall(fairlaunchABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}
