// for social sharing buttons on successful trades and on stats page
export const twitterHashtags = ['BinanceSmartChain', 'BSC', 'Binance']
export const twitterHashtagsWithTradingContest = []
export const socialLinksTwitter =
  '\n\nTelegram: https://t.me/AutoSharkFinance\n\nTrade here: https://autoshark.finance\n\n'
export const socialLinks =
  '\n\nTelegram: https://t.me/AutoSharkFinance \n\nTwitter: @AutoSharkFin'
export const tradingContestText = `Share your trade on Twitter with a simple click of a button. Spread the good word about AutoShark today.`
export const swapSuccessSharingDesc = `🦈 I've just %summary% on @AutoSharkFin!\n\n%transactionHash%\n\nGet a 50% fee rebate whilst trading on AutoShark!`

export {}