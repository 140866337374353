import { LendingConfig } from 'config/constants/types'
import fetchLending from './fetchLending'

const fetchLendings = async (vaultsToFetch: LendingConfig[]) => {
  const data = await Promise.all(
    vaultsToFetch.map(async (vaultConfig) => {
      const vault = await fetchLending(vaultConfig)

      return vault
    }),
  )
  return data
}

export default fetchLendings
