import tokens from './tokens'
import farms from './farms'
import { Ifo, IfoToken } from './types'

const jawsBnbLpToken: IfoToken = {
  symbol: farms[farms?.length - 1].lpSymbol,
  address: farms[farms?.length - 1].lpAddresses,
  decimals: 18,
  pcsMasterchef: true,
}

const finsBnbLpToken: IfoToken = {
  symbol: farms[0].lpSymbol,
  address: farms[0].lpAddresses,
  decimals: 18,
}

const ifos: Ifo[] = [
  {
    id: 'fins',
    address: '0x49281b98c2fF94765D017B91e23fFc232F363e09',
    isActive: false,
    loadIfoData: false,
    name: 'FINS',
    description: `The first token to be launched is an expansion of the AutoShark ecosystem. $FINS will play a part in cementing our standing amongst the leading DEXes on the Binance Smart Chain. Armed with a plethora of innovations such as dividends distribution, gamified NFTs and exploit-proof vaults, AutoShark is seeking to spearhead innovation essential for blockchain adoption.`,
    poolUnlimited: {
      saleAmount: '5,000,000 FINS',
      totalSupply: '500,000,000 FINS',
      raiseAmount: '$750,000',
      cakeToBurn: '$375,000',
      distributionRatio: 1,
    },
    currency: jawsBnbLpToken,
    token: tokens.fins,
    startBlockNumber: 10965800,
    endBlockNumber: 10965800,
    campaignId: '511080000',
    articleUrl:
      'https://medium.com/autosharkfin/making-waves-in-the-bsc-defi-scene-new-launchpad-next-gen-amm-e3a978549775',
    projectSite: 'https://autoshark.finance/',
    tokenOfferingPrice: 0.15,
    version: 1,
  },
  {
    id: 'squid',
    address: '0x772A41115d29786ce6405CdbbA23b0B740FD4De7',
    isActive: false,
    loadIfoData: false,
    name: 'SQUID',
    description: `Squid Stake is a decentralized yield farm aimed at providing high passive income through select farms and pools via Stable Pegged APR (SPA). Through the constant buyback and burn of the native $SQUID token and incentivising models such as liquidity mining, Squid Stake is dedicated to growing the platform through a sustainable tokenomics model that is semi-deflationary in nature while ensuring high sustainable yields for stakers.`,
    poolUnlimited: {
      saleAmount: '5,000,000 SQUID',
      totalSupply: '400,000,000 SQUID',
      raiseAmount: '$500,000',
      cakeToBurn: '$250,000',
      distributionRatio: 1,
    },
    currency: finsBnbLpToken,
    token: tokens.squid,
    startBlockNumber: 12161800,
    endBlockNumber: 12166600,
    campaignId: '511080001',
    articleUrl: 'https://medium.com/autosharkfin/announcing-2nd-launchpad-squidstake-46e9a91ddfea',
    projectSite: 'https://squidstake.com/',
    tokenOfferingPrice: 0.1,
    version: 1,
  },
  {
    id: 'atlantis',
    address: '0xa9F48feE822530037628Fcbbe2d7724221B1536D',
    isActive: false,
    loadIfoData: false,
    name: 'ATLANTIS',
    description: `Atlantis is a decentralized reserve currency protocol based on the ATLAS token. Each ATLAS token is backed by a basket of big cap assets (e.g. BUSD, BNB) in the Atlantis treasury, giving it an intrinsic value that it cannot fall below. Atlantis also introduces unique economic and game-theoretic dynamics into the market through staking and bonding.`,
    poolUnlimited: {
      saleAmount: '200,000 ATLAS',
      totalSupply: 'No Max Supply',
      raiseAmount: '$2,000,000',
      cakeToBurn: '$1,000,000',
      distributionRatio: 1,
    },
    currency: finsBnbLpToken,
    token: tokens.atlas,
    startBlockNumber: 12831140,
    endBlockNumber: 12835940,
    campaignId: '511080001',
    articleUrl: 'https://docs-atlantis.autoshark.finance/',
    projectSite: 'https://atlantis.autoshark.finance/',
    tokenOfferingPrice: 10,
    version: 1,
  },
  {
    id: 'yuklaswap',
    address: '0x7D0d2F341550dc3037eee30b108a886EF11aF6a6',
    isActive: false,
    loadIfoData: false,
    name: 'YUKLA',
    description: `YuklaSwap is the place in DeFI space where our users can earn high income while providing liquidity to our DEX / staking crypto-assets in our pools / participating in our geyser pools / playing 3D games on our gaming platform / earning special crafted NFTs. At YuklaSwap we believe in big future organic growth of Binance Smart Chain and other chains like Polygon or Solana. This is why our main goal is to provide our users all the benefits from this growth by expanding our pools, partnerships and integrating community-decided chains in our ecosystem.`,
    poolUnlimited: {
      saleAmount: '2,400,000 YUKLA',
      totalSupply: 'No Max Supply',
      raiseAmount: '$600,000',
      cakeToBurn: '$300,000',
      distributionRatio: 1,
    },
    currency: finsBnbLpToken,
    token: tokens.yukla,
    startBlockNumber: 14591660,
    endBlockNumber: 14606060,
    campaignId: '511080001',
    articleUrl: 'https://yuklaswap.gitbook.io/yuklaswap/',
    projectSite: 'https://yuklaswap.com/',
    tokenOfferingPrice: 0.25,
    version: 1,
    startDate: '22nd Jan, 2022 16:00 UTC'
  },
  {
    id: 'nts',
    address: '0x810B131d4AE72ddB270166fCB5a1f85c8d703AaF',
    isActive: true,
    loadIfoData: true,
    name: 'NTS',
    description: `The main goal of NTS Society is to create the first ever community driven source of information and news ”Crypto Journal” created by NTS community for all the people around the world, to have a truthful, decentralized and uncensored source of information.`,
    poolUnlimited: {
      saleAmount: '1,500,000 NTS',
      totalSupply: '10,000,000 NTS',
      raiseAmount: '$300,000',
      cakeToBurn: '$150,000',
      distributionRatio: 1,
    },
    currency: finsBnbLpToken,
    token: tokens.nts,
    startBlockNumber: 15533500,
    endBlockNumber: 15562300,
    campaignId: '511080001',
    articleUrl: 'https://medium.com/autosharkfin/5th-launchpad-nts-society-ea973d403ca8',
    projectSite: 'https://www.cryptojournal.ink/',
    tokenOfferingPrice: 0.20,
    version: 1,
    startDate: '24th Feb, 2022 10:00 UTC'
  },
  // {
  //   id: 'kalmar',
  //   address: '0x1aFB32b76696CdF05593Ca3f3957AEFB23a220FB',
  //   isActive: true,
  //   name: 'FINS',
  //   poolUnlimited: {
  //     saleAmount: '400,000 FINS',
  //     raiseAmount: '$800,000',
  //     cakeToBurn: '$200,000',
  //     distributionRatio: 1,
  //   },
  //   currency: jawsBnbLpToken,
  //   token: tokens.kalm,
  //   releaseBlockNumber: 7707736,
  //   campaignId: '511110000',
  //   articleUrl: 'https://pancakeswap.medium.com/kalmar-kalm-ifo-to-be-hosted-on-pancakeswap-4540059753e4',
  //   tokenOfferingPrice: 2.0,
  //   version: 2,
  // },
  // {
  //   id: 'hotcross',
  //   address: '0xb664cdbe385656F8c54031c0CB12Cea55b584b63',
  //   isActive: false,
  //   name: 'Hot Cross (HOTCROSS)',
  //   poolBasic: {
  //     saleAmount: '15,000,000 HOTCROSS',
  //     raiseAmount: '$750,000',
  //     cakeToBurn: '$375,000',
  //     distributionRatio: 0.3,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '35,000,000 HOTCROSS',
  //     raiseAmount: '$1,750,000',
  //     cakeToBurn: '$875,000',
  //     distributionRatio: 0.7,
  //   },
  //   currency: jawsBnbLpToken,
  //   token: tokens.hotcross,
  //   releaseBlockNumber: 7477900,
  //   campaignId: '511100000',
  //   articleUrl: 'https://pancakeswap.medium.com/hot-cross-hotcross-ifo-to-be-hosted-on-pancakeswap-10e70f1f6841',
  //   tokenOfferingPrice: 0.05,
  //   version: 2,
  // },
  // {
  //   id: 'horizon',
  //   address: '0x6137B571f7F1E44839ae10310a08be86D1A4D03B',
  //   isActive: false,
  //   name: 'Horizon Protocol (HZN)',
  //   poolBasic: {
  //     saleAmount: '3,000,000 HZN',
  //     raiseAmount: '$750,000',
  //     cakeToBurn: '$375,000',
  //     distributionRatio: 0.3,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '7,000,000 HZN',
  //     raiseAmount: '$1,750,000',
  //     cakeToBurn: '$875,000',
  //     distributionRatio: 0.7,
  //   },
  //   currency: jawsBnbLpToken,
  //   token: tokens.hzn,
  //   releaseBlockNumber: 6581111,
  //   campaignId: '511090000',
  //   articleUrl: 'https://pancakeswap.medium.com/horizon-protocol-hzn-ifo-to-be-hosted-on-pancakeswap-51f79601c9d8',
  //   tokenOfferingPrice: 0.25,
  //   version: 2,
  // },
  // {
  //   id: 'belt',
  //   address: '0xc9FBedC033a1c479a6AD451ffE463025E92a1d38',
  //   isActive: false,
  //   name: 'Belt (BELT)',
  //   poolUnlimited: {
  //     saleAmount: '150,000 BELT',
  //     raiseAmount: '$3,000,000',
  //     cakeToBurn: '$1,500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: jawsBnbLpToken,
  //   token: tokens.belt,
  //   releaseBlockNumber: 5493919,
  //   campaignId: '511080000',
  //   articleUrl: 'https://pancakeswap.medium.com/belt-fi-belt-ifo-to-be-hosted-on-pancakeswap-353585117e32',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },

  // {
  //   id: 'watch',
  //   address: '0x55344b55C71Ad8834C397E6e08dF5195cF84fe6d',
  //   isActive: false,
  //   name: 'Yieldwatch (WATCH)',
  //   poolUnlimited: {
  //     saleAmount: '8,000,000 WATCH',
  //     raiseAmount: '$800,000',
  //     cakeToBurn: '$400,000',
  //     distributionRatio: 1,
  //   },
  //   currency: jawsBnbLpToken,
  //   token: tokens.watch,
  //   releaseBlockNumber: 5294924,
  //   campaignId: '511070000',
  //   articleUrl: 'https://pancakeswap.medium.com/yieldwatch-watch-ifo-to-be-hosted-on-pancakeswap-d24301f17241',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'berry',
  //   address: '0x5d028cE3435B2bB9AceBfaC599EEbA1ccD63d7dd',
  //   isActive: false,
  //   name: 'Berry (BRY)',
  //   poolUnlimited: {
  //     saleAmount: '2,000,000 BRY',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: jawsBnbLpToken,
  //   token: tokens.bry,
  //   releaseBlockNumber: 4750968,
  //   campaignId: '511060000',
  //   articleUrl: 'https://pancakeswap.medium.com/berry-bry-ifo-to-be-hosted-on-pancakeswap-b4f9095e9cdb',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'soteria',
  //   address: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
  //   isActive: false,
  //   name: 'Soteria (wSOTE)',
  //   poolUnlimited: {
  //     saleAmount: '1,500,000 wSOTE',
  //     raiseAmount: '$525,000',
  //     cakeToBurn: '$262,500',
  //     distributionRatio: 1,
  //   },
  //   currency: jawsBnbLpToken,
  //   token: tokens.wsote,
  //   releaseBlockNumber: 4086064,
  //   campaignId: '511050000',
  //   articleUrl: 'https://pancakeswap.medium.com/soteria-sota-ifo-to-be-hosted-on-pancakeswap-64b727c272ae',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'helmet',
  //   address: '0xa32509d760ee47Eb2Be96D338b5d69B5FBa4eFEB',
  //   isActive: false,
  //   name: 'Helmet.insure (Helmet)',
  //   poolUnlimited: {
  //     saleAmount: '10,000,000 Helmet',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: jawsBnbLpToken,
  //   token: tokens.helmet,
  //   releaseBlockNumber: 3771926,
  //   campaignId: '511040000',
  //   articleUrl: 'https://pancakeswap.medium.com/1-000-000-helmet-helmet-ifo-to-be-hosted-on-pancakeswap-3379a2a89a67',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'tenet',
  //   address: '0xB922aA19A2603A07C6C9ED6c236618C9bac51f06',
  //   isActive: false,
  //   name: 'Tenet (TEN)',
  //   poolUnlimited: {
  //     saleAmount: '1,000,000 TEN',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: jawsBnbLpToken,
  //   token: tokens.ten,
  //   releaseBlockNumber: 3483883,
  //   campaignId: '511030000',
  //   articleUrl: 'https://pancakeswap.medium.com/tenet-ten-ifo-to-be-hosted-on-pancakeswap-b7e1eb4cb272',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'ditto',
  //   address: '0x570c9eB19553526Fb35895a531928E19C7D20788',
  //   isActive: false,
  //   name: 'Ditto (DITTO)',
  //   poolUnlimited: {
  //     saleAmount: '700,000 DITTO',
  //     raiseAmount: '$630,000',
  //     cakeToBurn: '$315,000',
  //     distributionRatio: 1,
  //   },
  //   currency: jawsBnbLpToken,
  //   token: tokens.ditto,
  //   releaseBlockNumber: 3279767,
  //   campaignId: '511020000',
  //   articleUrl: 'https://pancakeswap.medium.com/ditto-money-ditto-ifo-to-be-hosted-on-pancakeswap-342da3059a66',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'blink',
  //   address: '0x44a9Cc8463EC00937242b660BF65B10365d99baD',
  //   isActive: false,
  //   name: 'BLINk (BLK)',
  //   poolUnlimited: {
  //     saleAmount: '100,000,000 BLINK',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: jawsBnbLpToken,
  //   token: tokens.blink,
  //   releaseBlockNumber: 3279767,
  //   campaignId: '511010000',
  //   articleUrl: 'https://medium.com/pancakeswap/1-000-000-ifo-blink-joins-pancakeswap-15841800bdd8',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
]

export default ifos
