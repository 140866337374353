import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import genericVaultABI from 'config/abi/genericVault.json'
import dashboardBSCABI from 'config/abi/dashboardBSC.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { VaultConfig } from 'config/constants/types'
import { REVERSE_NFT_ADDRESS_MAP } from 'config/constants/nfts'

function chunk(arr: any[], len: number) {
  const chunks = []
  let i = 0
  const n = arr.length

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}

export const fetchVaultUserAllowances = async (account: string, vaultsToFetch: VaultConfig[]) => {
  const calls = vaultsToFetch.map((vault) => {
    const lpContractAddress = getAddress(vault.singleStake ? vault.token.address : vault.lpAddresses)
    return { address: lpContractAddress, name: 'allowance', params: [account, vault.vaultAddress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchVaultUserTokenBalances = async (account: string, vaultsToFetch: VaultConfig[]) => {
  const calls = vaultsToFetch.map((vault) => {
    const lpContractAddress = getAddress(vault.singleStake ? vault.token.address : vault.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchVaultUserStakedBalances = async (account: string, vaultsToFetch: VaultConfig[]) => {
  const calls = vaultsToFetch.map((vault) => {
    return {
      address: vault.vaultAddress,
      name: 'principalOf',
      params: [account],
    }
  })

  const rawStakedBalances = await multicall(genericVaultABI, calls)

  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })

  return parsedStakedBalances
}

export const fetchVaultUserEarnings = async (account: string, vaultsToFetch: VaultConfig[]) => {
  const calls = vaultsToFetch.map((vault) => {
    return {
      address: vault.dashboard,
      name: 'infoOfPool',
      params: [vault.vaultAddress, account],
    }
  })

  const chunks = chunk(calls, 40)

  let rawInfo = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const call of chunks) {
    // eslint-disable-next-line no-await-in-loop
    const data = await multicall(dashboardBSCABI, call)
    rawInfo = [...rawInfo, ...data];
  }

  const parsedEarnings = rawInfo.map((earnings) => {
    return {
      base: new BigNumber(earnings?.[0]?.pBASE?._hex).toJSON(),
      jaws: new BigNumber(earnings?.[0]?.pJAWS?._hex).toJSON(),
    }
  })

  return parsedEarnings
}

export const fetchVaultUserNftSlots = async (account: string, vaultsToFetch: VaultConfig[]) => {
  const calls = vaultsToFetch.map((vault) => {
    return {
      address: vault.vaultAddress,
      name: 'getSlots',
      params: [account],
    }
  })
  const rawInfo = await multicall(genericVaultABI, calls)

  return rawInfo
}

export const fetchVaultUserNftTokenIds = async (account: string, vaultsToFetch: VaultConfig[]) => {
  const calls = vaultsToFetch.map((vault) => {
    return {
      address: vault.vaultAddress,
      name: 'getTokenIds',
      params: [account],
    }
  })
  const addressCalls = vaultsToFetch.map((vault) => {
    return {
      address: vault.vaultAddress,
      name: 'getSlots',
      params: [account],
    }
  })

  const rawInfo = await multicall(genericVaultABI, calls)
  const addressInfo = await multicall(genericVaultABI, addressCalls)
  const parsedTokens = rawInfo.map((tokens, index) => {
    const addresses = addressInfo[index]
    return tokens.map((item, _index) => {
      return {
        series: REVERSE_NFT_ADDRESS_MAP[addresses[_index]],
        id: new BigNumber(item._hex).toJSON(),
      }
    })
  })
  return parsedTokens
}

export const fetchVaultUserBoost = async (account: string, vaultsToFetch: VaultConfig[]) => {
  const calls = vaultsToFetch.map((vault) => {
    return {
      address: vault.vaultAddress,
      name: 'getBoost',
      params: [account],
    }
  })

  const rawInfo = await multicall(genericVaultABI, calls)

  const parsedEarnings = rawInfo.map((earnings) => {
    return new BigNumber(earnings?.[0]._hex).toJSON()
  })

  return parsedEarnings
}
