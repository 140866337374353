import React from 'react'
import { useTranslation } from 'contexts/Localization'
// import styled from 'styled-components'
import { Modal, Text, Button } from '@autoshark-finance/uikit'
import useTheme from 'hooks/useTheme'
import tokens from 'config/constants/tokens'

interface NotEnoughTokensModalProps {
  tokenSymbol: string
  onDismiss?: () => void
}

// const StyledLink = styled(Link)`
//   width: 100%;
// `

const NotEnoughTokensModal: React.FC<NotEnoughTokensModalProps> = ({ tokenSymbol, onDismiss }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  return (
    <Modal
      title={t('%symbol% required', { symbol: tokenSymbol })}
      onDismiss={onDismiss}
      headerBackground={theme.colors.gradients.cardHeader}
    >
      <Text color="failure" bold>
        {t('Insufficient %symbol% balance', { symbol: tokenSymbol })}
      </Text>
      <Text mt="24px">{t('You’ll need %symbol% to stake in this pool!', { symbol: tokenSymbol })}</Text>
      {tokenSymbol !== 'cMERL' ? (
        <>
          <Text>
            {t('Buy some %symbol%, or make sure your %symbol% isn’t in another pool or LP.', {
              symbol: tokenSymbol,
            })}
          </Text>
          <Button mt="24px" as="a" external href={tokens[tokenSymbol].alternateSwapLink ?? '/swap'}>
            {t('Buy')} {tokenSymbol}
          </Button>
        </>
      ) : (
        <>
          <Text>
            {t('%symbol% is an exclusive token for compensation. It cannot be purchased from any swap.', {
              symbol: tokenSymbol,
            })}
          </Text>
        </>
      )}
      {/* <StyledLink href="https://yieldwatch.net" external>
        <Button variant="secondary" mt="8px" width="100%">
          {t('Locate Assets')}
          <OpenNewIcon color="primary" ml="4px" />
        </Button>
      </StyledLink> */}
      <Button variant="text" onClick={onDismiss}>
        {t('Close Window')}
      </Button>
    </Modal>
  )
}

export default NotEnoughTokensModal
