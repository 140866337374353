import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { farmsConfig } from 'config/constants'
import useRefresh from 'hooks/useRefresh'
import { useBusdPriceFromVault, useVaultFromLpSymbol } from 'state/vaults/hooks'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync, nonArchivedFarms } from '.'
import { State, Farm, FarmsState, VaultsState } from '../types'


export const usePollFarmsData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))
    // dispatch(fetchOriginalFarmsPublicDataAsync(pids))

    if (account) {
      dispatch(fetchFarmUserDataAsync({ account, pids }))
    }
  }, [includeArchive, dispatch, slowRefresh, account])
}

/**
 * Fetches the "core" farm data used globally
 * 251 = JAWS-BNB LP
 * 252 = BUSD-BNB LP
 */
export const usePollCoreFarmData = () => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync([1, 2, 3]))
  }, [dispatch, fastRefresh])
}

export const useFarms = (): FarmsState => {
  const farms = useSelector((state: State) => state.farms)
  return farms
}

export const useVaults = (): VaultsState => {
  const farms = useSelector((state: State) => state.vaults)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromPidAndChef = (pid, chef): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid && f.masterChef === chef))
  return farm
}

export const useFarmFromLpSymbol = (lpSymbol: string, chef: string): Farm => {
  const farm = useSelector((state: State) =>
    state.farms.data.find((f) => f.lpSymbol === lpSymbol && f.masterChef === chef),
  )
  return farm
}

export const useFarmUser = (pid, chef) => {
  const farm = useFarmFromPidAndChef(pid, chef)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
  }
}

// Return the base token price for a farm, from a given pid
export const useBusdPriceFromPid = (pid: number, chef: string): BigNumber => {
  const farm = useFarmFromPidAndChef(pid, chef)
  return farm && new BigNumber(farm.token.busdPrice)
}

export const useLpTokenPrice = (symbol: string, chef: string) => {
  const farm = useFarmFromLpSymbol(symbol, chef)
  const vault = useVaultFromLpSymbol(symbol)
  const entity = farm === undefined ? vault : farm;
  const farmTokenPriceInUsd = useBusdPriceFromPid(entity.pid, chef)
  const vaultTokenPriceInUsd =  useBusdPriceFromVault(vault?.vaultAddress)
  const entityTokenPriceInUsd = farm === undefined ? vaultTokenPriceInUsd : farmTokenPriceInUsd;
  let lpTokenPrice = BIG_ZERO

  if (entity.lpTotalSupply && entity.lpTotalInQuoteToken) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = entityTokenPriceInUsd.times(entity.tokenAmountTotal)
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = getBalanceAmount(new BigNumber(entity.lpTotalSupply))
    lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
  }

  return lpTokenPrice
}

// /!\ Deprecated , use the BUSD hook in /hooks

export const usePriceBnbBusd = (): BigNumber => {
  // const bnbBusdFarm = useFarmFromPid(252)
  // return new BigNumber(bnbBusdFarm.quoteToken.busdPrice)
  return new BigNumber(0)
}

export const usePriceCakeBusd = (): BigNumber => {
  // const cakeBnbFarm = useFarmFromPid(251)
  // return new BigNumber(cakeBnbFarm.token.busdPrice)
  return new BigNumber(0)
}
