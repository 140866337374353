import BigNumber from 'bignumber.js'
import React from 'react'
import styled from 'styled-components'
import { BIG_ZERO } from 'utils/bigNumber'
import { Flex, Text, Box } from '@autoshark-finance/uikit'
import { useTranslation } from 'contexts/Localization'
import { PoolCategory } from 'config/constants/types'
import { Pool } from 'state/types'
import ApprovalAction from './ApprovalAction'
import StakeActions from './StakeActions'
import HarvestActions from './HarvestActions'

const InlineText = styled(Text)`
  display: inline;
`

interface CardActionsProps {
  pool: Pool
  stakedBalance: BigNumber
}

const CardActions: React.FC<CardActionsProps> = ({ pool, stakedBalance }) => {
  const { sousId, stakingToken, earningToken, harvest, poolCategory, userData, earningTokenPrice, disableRewards } =
    pool
  // Pools using native BNB behave differently than pools using a token
  const isBnbPool = poolCategory === PoolCategory.BINANCE
  const { t } = useTranslation()
  const allowance = userData?.allowance ? new BigNumber(userData.allowance) : BIG_ZERO
  const stakingTokenBalance = userData?.stakingTokenBalance ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO
  const earnings = userData?.pendingReward ? new BigNumber(userData.pendingReward) : BIG_ZERO
  const needsApproval = !allowance.gt(0) && !isBnbPool
  const isStaked = stakedBalance.gt(0)
  const isLoading = !userData

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
        {harvest && !disableRewards && (
          <>
            <Box display="inline">
              <InlineText color="secondary" textTransform="uppercase" bold fontSize="12px">
                {`${earningToken.symbol} `}
              </InlineText>
              <InlineText color="textSubtle" textTransform="uppercase" bold fontSize="12px">
                {t('Earned')}
              </InlineText>
            </Box>
            <HarvestActions
              earnings={earnings}
              earningToken={earningToken}
              sousId={sousId}
              earningTokenPrice={earningTokenPrice}
              isBnbPool={isBnbPool}
              isLoading={isLoading}
            />
          </>
        )}
        {disableRewards && (
          <>
            <Box display="inline" mb="20px">
              <Text color="primary" mb="10px">
                {t('Rewards have been disabled for this Ocean.')}
              </Text>
              <Text>
                {t(
                  'Please unstake your tokens and re-stake them in other Live Oceans..',
                )}
              </Text>
            </Box>
          </>
        )}
        {(!disableRewards || isStaked) && (
          <>
            <Box display="inline">
              <InlineText color={isStaked ? 'secondary' : 'textSubtle'} textTransform="uppercase" bold fontSize="12px">
                {isStaked ? stakingToken.symbol : t('Stake')}{' '}
              </InlineText>
              <InlineText color={isStaked ? 'textSubtle' : 'secondary'} textTransform="uppercase" bold fontSize="12px">
                {isStaked ? t('Staked') : `${stakingToken.symbol}`}
              </InlineText>
            </Box>
            {needsApproval ? (
              <ApprovalAction pool={pool} isLoading={isLoading} />
            ) : (
              <StakeActions
                isLoading={isLoading}
                pool={pool}
                stakingTokenBalance={stakingTokenBalance}
                stakedBalance={stakedBalance}
                isStaked={isStaked}
              />
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}

export default CardActions
